/*
 * @Date: 2022-04-22 10:33:09
 * @LastEditors: Save
 * @LastEditTime: 2022-04-22 14:26:28
 * @FilePath: /src/locales/index.ts
 * @Description: 
 */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector';

// config language
import en from "./en.json"
import zh from './zh.json'

const resources = {
  en: {
    translation: en
  },
  zh: {
    translation: zh
  }
};

i18n
  .use(initReactI18next)
  .use(Cache)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;