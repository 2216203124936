import { ApiUrlArticles } from "@/api/api.url";
import { GlobalAxios } from "@/api/http";

function onGetArticleList(params: ARTICLE.getParams) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlArticles.getList
    },
    alertMsg: "Query article list"
  }).fetch("GET", params)
}

function onPostCreateArticle(data: ARTICLE.createData) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlArticles.postCreate
    },
    alertMsg: "create articles"
  }).fetch("POST", data)
}

function onDeleteArticle(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlArticles.deleteDetail.replace(":id", id)
    },
    alertMsg: "Delete article by id"
  }).fetch("DELETE")
}

function onPostUploadImageToS3(formData: FormData) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlArticles.postUploadImageToS3,
      headers: { "Content-Type": "multipart/form-data" },
    },
    alertMsg: "Upload image to S3"
  }).fetch<{url: string}>("POST", formData)
}

function onGetArticleDetail(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlArticles.getDetail.replace(":id", id)
    },
    alertMsg: "Get single article by id"
  }).fetch("GET")
}

function onPutArticleDetail(id: string, data: ARTICLE.createData) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlArticles.putDetail.replace(":id", id)
    },
    alertMsg: "Update article by id"
  }).fetch("PUT", data)
}

function onGetArticleReviewsList(id: string, params: BASE.ListQuery) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlArticles.getReviewsList.replace(":id", id)
    },
    alertMsg: "Query article reviews list"
  }).fetch("GET", params)
}

function onPostCreateArticleReviews(id: string, data: ARTICLE.reviewsCreateData) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlArticles.putCreateReviews.replace(":id", id)
    },
    alertMsg: "Create article review"
  }).fetch("POST", data)
}


function onDeleteArticleReviews(id: string, reviewsId: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlArticles.deleteReviews.replace(":id", id).replace(":reviewsId", reviewsId)
    },
    alertMsg: "Delete article review by id"
  }).fetch("DELETE")
}

export {
  onPostCreateArticle,
  onGetArticleList,
  onDeleteArticle,
  onPostUploadImageToS3,
  onGetArticleDetail,
  onPutArticleDetail,
  onGetArticleReviewsList,
  onPostCreateArticleReviews,
  onDeleteArticleReviews
}