import { ENV_CONFIG } from "@/interface/envConfig";

/*
 * @Date: 2022-04-22 22:34:49
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-27 15:27:14
 * @FilePath: /kittens/src/api/api.url.ts
 * @Description: 
 */
export const envConfig = (window as unknown as envThis).envConfig as ENV_CONFIG.options
export const webscoketUrl = envConfig.REACT_APP_WEBSCOKET_URL

export const LoginApiUrl = {
  Register: `register`,
};

export const VerifyApiUrl = {
  Captchaid: `verify/captchaid`,
};

export const ApiUrlLogin = {
  Register: `register`,
  Forgot: `forgot`,
  Login: `login`,
  CurrentLogout: `current/logout`,
  CurrentUser: `current/user`,
  CurrentPassword: `current/password`,
  CurrentDestroy: `current/destroy`,
  CurrentEmail: `current/email`
};

export const ApiUrlVerify = {
  Captchaid: `verify/captchaid`,
  Captcha: `verify/captcha`,
  SendCode: `verify/sendcode`,
};

export const ApiUrlGateway = {
  CreateUser: `users`,
  Role: `roles`,
  List: `gateways`,
  Latest: `gateways/{{id}}/latest`,
  Get: `gateways/{{id}}`,
  Host: `gateways/{{id}}/changehost`,
  Delbatch: `gateways`,
  gatewayLatests: `gatewaylatests`,
  makerList: `makers`,
  cancelHost: `gateways/{{id}}/cancelhost`,
  gatewaysDownlink: `gateways/{{id}}/downlink`,
};

export const ApiUrlClient = {
  User: `users`,
  UserFreezed: `users/{{id}}/freezed`,
  UserActive: `users/{{id}}/active`,
  UserDelete: `users/`,
  UserRole: `users/{{id}}/role`,
};

export const ApiUrlRule = {
  rules: `rules`,
  rulesTrigger: `rules/{id}/trigger`,
};

export const ApiUrlhosts = {
  hostings: `hostings`,
};

export const ApiUrlNotifications = {
  notifications: `thingactionlog`,
};

export const ApiUrlThingGroup = {
  thinggroups: `thinggroups`,
  thinggroupsParent: `thinggroups/{id}/parent`,
  thinggroupauthorizeds: `thinggroupauthorizeds`,
  gatewayToGroups: `thinggroups/{id}/gateways`,
  thingToGroups: `thinggroups/{id}/things`,
  delThingGroupShareWithMe: `thinggroupauthorizeds/{groupId}/my`,
  getInit: `thinggroups/init`
}

export const ApiUrlThingrules = {
  thingrules: `thingrules`,
  enable: `thingrules/{id}/enable`,
  thingrulesMute: `thingrules/mute`
}

export const ApiUrlThingType = {
  types: `thingtypes`
}

export const ApiUrlThingMetrics = {
  query: `thingmetrics/query`
}

export const ApiUrlThings = {
  things: `things`,
  thingsGroup: `things/{id}/group`,
  thingsActive: `things/active`,
  thingsGroupAlias: `things/{id}/alias`,
  thingsActivated: `things/{id}/activated`,
  thingsDisabled: `things/{id}/disabled`,
  thingsDevice: `things`,
  thingsUpdategroup: `things/updategroup`,
  thingsManualactive: `things/manualactive`
}

export const ApiUrlThingSettings = {
  thingSettings: `thingsettings`,
  thingSettingsSave: `thingsettings/save`
}

/* thingsHistory */
export const ApiUrlThingHistory = {
  thinghistory: `things/{id}/history`,
}

/* Notification list */
export const ApiUrlThingNotification = {
  thingNotifications: `thingactionlog`,
}

export const ApiUrlThingShare = {
  thingsharers: `thingsharers`,
  details: `thingsharers/{id}`
}

export const ApiUrlThingDownLink = {
  publish: `thingdownlink/publish`
}

export const ApiUrlThingClaimKeys = {
  convert: `thingclaimkeys/convert`,
  convertFile: `thingclaimkeys/convert/{key}/file`,
  convertStatus: `thingclaimkeys/convert/{key}/status`,
}

export const ApiUrlwallets = {
  wallets: `wallets`,
}

export const ApiUrlChannel = {
  channelType: "thingchannel/types"
}

export const ApiUrlSubscription = {
  subscriptions: "subscriptions",
  claim: "subscriptions/claim"
}

export const ApiUrlArticles = {
  getList: "articles", // Query article list
  getDetail: "articles/:id",
  postCreate: "articles", // Create article
  postUploadImageToS3: "articles/upload", // Upload image to S3
  deleteDetail: "articles/:id",
  putDetail: "articles/:id",
  getReviewsList: "articles/:id/reviews",
  putCreateReviews: "articles/:id/reviews",
  deleteReviews: "articles/:id/reviews/:reviewsId"
}

