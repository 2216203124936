/*
 * @Date: 2022-04-26 13:06:12
 * @LastEditors: Save
 * @LastEditTime: 2022-08-04 02:59:20
 * @FilePath: /src/store/slice/user.slice.ts
 * @Description: 
 */

import cloneDeep from "lodash/cloneDeep";
import { getRole } from "@/services/gateway";
import { RequestData } from "@ant-design/pro-table";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initState {
  RoleEnum: any;
  Rolelist: string[];
  UsersData: RequestData<BASE.ListQuery>;
  UserDataPageInfo: {
    current: number;
    pageSize: number;
  };
}

const initialState: initState = {
  RoleEnum: {},
  Rolelist: [],
  UsersData: {
    data: [],
    success: false,
    total: 0,
  },
  UserDataPageInfo: {
    current: 1,
    pageSize: 10,
  },
}

const UserSlice = createSlice({
  name: "UserSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    setUsersList: (state, action: PayloadAction<{UsersData: RequestData<BASE.ListQuery>, pageInfo: {current: number, pageSize: number}}>) => {
      return {
        ...state,
        UsersData: action.payload.UsersData,
        pageInfo: action.payload.pageInfo
      }
    },
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(FETCH_GOLE.fulfilled, (state, action: PayloadAction<any>) => {
      state.RoleEnum = action.payload.RoleEnum
      state.Rolelist = action.payload.Role
    })
  }
})

const FETCH_GOLE = createAsyncThunk(
  "FETCH_GOLE", async () => {
    const res = await getRole()
    if (res.success) {
      const NewRoleEnum: any = {};
      const list = res.payload.list;
      list.forEach((item: any) => {
        NewRoleEnum[item] = { text: item };
      });
      return { RoleEnum: NewRoleEnum, Role: list }
    }
    return { RoleEnum: [], Role: [] }
  }
)

export { FETCH_GOLE }
export default UserSlice