/*
 * @Date: 2022-04-25 13:55:02
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-20 15:30:33
 * @FilePath: /kittens/src/store/slice/makers.slice.ts
 * @Description: 
 */

import cloneDeep from "lodash/cloneDeep";
import { getMakerList } from "@/services/helium.fetch";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface initState {
  maker_data: any;
}

const initialState: initState = {
  maker_data: null
}

const MakersSlice = createSlice({
  name: "MakersSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(FETCH_MAKERS_LIST.fulfilled, (state, action) => {
      const data: any = {};
      action.payload.forEach((item: any) => {
        data[item.id] = item;
      });
      state.maker_data = data;
    })
  }
})

const FETCH_MAKERS_LIST = createAsyncThunk(
  "FETCH_MAKERS_LIST", async () => {
    const res = await getMakerList()
    return res
  }
)

export { FETCH_MAKERS_LIST }
export default MakersSlice