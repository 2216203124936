/*
 * @Date: 2022-04-25 13:55:02
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-25 18:53:45
 * @FilePath: /kittens/src/store/slice/wallets.slice.ts
 * @Description: 
 */

import cloneDeep from "lodash/cloneDeep";
import { onGetWallets } from "@/services/wallets";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { walletName } from "@/assets/utils/hash-name"
interface initState {
  wallets: USER.Wallet[];
}

const initialState: initState = {
  wallets: []
}

const  WalletsSlice = createSlice({
  name: "WalletsSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  },
  extraReducers: builder => {
    builder
    .addCase( FETCH_WALLETS.fulfilled, (state, action) => {
      if (action.payload.success) {
        for (const wallet of action.payload?.payload?.wallets) {
          state.wallets.push({
            label: walletName(wallet),
            value: wallet,
            source: {
              hash: wallet,
              name: walletName(wallet),
              type: 'helium',
              text: walletName(wallet)
            }
          });
        }
      }
    })
  }
})

const  FETCH_WALLETS = createAsyncThunk(
  "FETCH_WALLETS", async () => {
    const res = await onGetWallets()
    return res
  }
)

export { FETCH_WALLETS }
export default WalletsSlice