export default [
  'accountant',
  'acupuncturist',
  'actor',
  'actuary',
  'advisor',
  'aide',
  'ambassador',
  'animator',
  'archer',
  'architect',
  'artist',
  'astronaut',
  'astronomer',
  'athlete',
  'attorney',
  'auctioneer',
  'author',
  'babysitter',
  'baker',
  'banker',
  'barber',
  'bartender',
  'bellhop',
  'biologist',
  'blacksmith',
  'bookkeeper',
  'bowler',
  'builder',
  'butler',
  'calligrapher',
  'captain',
  'cardiologist',
  'caregiver',
  'carpenter',
  'cartographer',
  'cartoonist',
  'cashier',
  'catcher',
  'caterer',
  'cellist',
  'chaplain',
  'chauffeur',
  'chef',
  'chemist',
  'clerk',
  'coach',
  'cobbler',
  'composer',
  'concierge',
  'consul',
  'contractor',
  'cook',
  'cop',
  'courier',
  'cryptographer',
  'custodian',
  'dancer',
  'dentist',
  'deputy',
  'dermatologist',
  'designer',
  'detective',
  'dictator',
  'director',
  'diver',
  'doctor',
  'doorman',
  'driver',
  'drummer',
  'ecologist',
  'economist',
  'editor',
  'educator',
  'electrician',
  'engineer',
  'entertainer',
  'entomologist',
  'entrepreneur',
  'executive',
  'explorer',
  'exporter',
  'exterminator',
  'falconer',
  'farmer',
  'financier',
  'firefighter',
  'fisherman',
  'flutist',
  'foreman',
  'freelancer',
  'fundraiser',
  'gardener',
  'gatherer',
  'gemcutter',
  'general',
  'geneticist',
  'geographer',
  'geologist',
  'golfer',
  'governor',
  'grocer',
  'guide',
  'hairdresser',
  'handyman',
  'harpist',
  'hobo',
  'hunter',
  'illustrator',
  'importer',
  'instructor',
  'intern',
  'internist',
  'interpreter',
  'inventor',
  'investigator',
  'jailer',
  'janitor',
  'jester',
  'jeweler',
  'jockey',
  'journalist',
  'judge',
  'laborer',
  'landlord',
  'landscaper',
  'laundress',
  'lawyer',
  'lecturer',
  'librarian',
  'librettist',
  'lifeguard',
  'linguist',
  'lobbyist',
  'locksmith',
  'lyricist',
  'magician',
  'maid',
  'manager',
  'manufacturer',
  'marine',
  'marketer',
  'mason',
  'mathematician',
  'mayor',
  'mechanic',
  'messenger',
  'midwife',
  'miner',
  'model',
  'monk',
  'muralist',
  'musician',
  'navigator',
  'negotiator',
  'notary',
  'novelist',
  'nun',
  'nurse',
  'oboist',
  'operator',
  'optician',
  'oracle',
  'orderly',
  'ornithologist',
  'painter',
  'paleontologist',
  'paralegal',
  'pathologist',
  'pawnbroker',
  'peddler',
  'pediatrician',
  'percussionist',
  'performer',
  'pharmacist',
  'philanthropist',
  'philosopher',
  'photographer',
  'physician',
  'physicist',
  'pianist',
  'pilot',
  'pitcher',
  'plumber',
  'poet',
  'police',
  'policeman',
  'politician',
  'producer',
  'professor',
  'programmer',
  'psychiatrist',
  'psychologist',
  'publisher',
  'quarterback',
  'quilter',
  'radiologist',
  'rancher',
  'ranger',
  'receptionist',
  'referee',
  'registrar',
  'reporter',
  'researcher',
  'restaurateur',
  'retailer',
  'retiree',
  'sailor',
  'salesperson',
  'samurai',
  'saxophonist',
  'scholar',
  'scientist',
  'scout',
  'seamstress',
  'senator',
  'sheriff',
  'singer',
  'smith',
  'socialite',
  'soldier',
  'spy',
  'statistician',
  'stockbroker',
  'surgeon',
  'surveyor',
  'swimmer',
  'tailor',
  'taxidermist',
  'teacher',
  'technician',
  'tiler',
  'toolmaker',
  'trader',
  'trainer',
  'translator',
  'treasurer',
  'tutor',
  'typist',
  'umpire',
  'undertaker',
  'usher',
  'veteran',
  'veterinarian',
  'vicar',
  'violinist',
  'warden',
  'warrior',
  'watchmaker',
  'weaver',
  'welder',
  'woodcarver',
  'workman',
  'wrangler',
  'writer',
  'xylophonist',
  'yodeler',
  'zookeeper',
  'zoologist',
]