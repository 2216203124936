import { HeliumIoApi } from "@/api/api.external";

async function getEarnings() {
    try {
        const req = await fetch(HeliumIoApi.earning);
        if (req.status === 200) {
        const body = await req.json();
        return body.data;
        }
    } catch (error) {
        return false;
    }
}

export {
    getEarnings,
};
