import cloneDeep from "lodash/cloneDeep";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { onGetArticleList } from "@/services/article";
import { requestDataFunction } from "@/assets/utils/base";
import { ArticlesStatusEnum } from "@/interface/enum";

interface initState {
  PublishedListOps: {
    List: THINGS.ThingTemplateFormatList
    Loading: boolean
    Total: number
    params: ARTICLE.getParams
    ListStauts: "0" | "1" | "2",
    sourceList: THINGS.ThingTemplateFormatList
  }
}

const initialState: initState = {
  PublishedListOps: {
    List: [],
    Loading: false,
    Total: 0,
    params: { current: 1, pageSize: 10, source: "published" },
    ListStauts: "0",
    sourceList: []
  }
}

const ArticleSlice = createSlice({
  name: "articleSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(FETCH_GET_ARTICLE_PUBLISHED_LIST.pending, (state, action) => {
      state.PublishedListOps.Loading = true
      state.PublishedListOps.params = action.meta.arg
      if (state.PublishedListOps.params.current === 1) {
        state.PublishedListOps.List = []
        state.PublishedListOps.ListStauts = "0"
      }
    })
    .addCase(FETCH_GET_ARTICLE_PUBLISHED_LIST.fulfilled, (state, action) => {
      const { data, total } = action.payload
      state.PublishedListOps.Loading = false
      state.PublishedListOps.Total = total || 0
      if (data) {
        if (state.PublishedListOps.List.length) {
          const newList = [
            ...state.PublishedListOps.List, 
            ...data
          ]
          state.PublishedListOps.List =  newList
        } else {
          state.PublishedListOps.List = data
        }
      }
      if (state.PublishedListOps.List.length) {
        state.PublishedListOps.ListStauts = "1"
      } else {
        state.PublishedListOps.ListStauts = "2"
      }
      state.PublishedListOps.sourceList = state.PublishedListOps.List
    })
  }
})
// published
export const FETCH_GET_ARTICLE_PUBLISHED_LIST = createAsyncThunk(
  "FETCH_GET_ARTICLE_PUBLISHED_LIST", async (params: ARTICLE.getParams) => {
    const setpOne = await onGetArticleList(params)
    const setpTwo = await requestDataFunction<any>(setpOne, list => {
      return list.map(item => {
        item['status'] = ArticlesStatusEnum[item.status as ARTICLE.ArticlesStatusName]
        return item
      })
    });
    return setpTwo
  }
)

export default ArticleSlice