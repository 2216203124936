/*
 * @Date: 2022-07-27 00:48:47
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-15 04:02:47
 * @FilePath: /kittens/src/store/slice/socket.slice.ts
 * @Description: 
 */
import cloneDeep from "lodash/cloneDeep";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SCOKET.iniiaState = {
  socketThingList: [],
  socketThingItem: null
}

const SocketSlice = createSlice({
  name: "socketSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    updateWsThingList: (state, action: PayloadAction<SCOKET.onMessageProps[]>) => {
      const socketList = action.payload
      const socketThingList: SCOKET.socketThingList = []
      socketList.forEach(messageItem => {
        if (Reflect.has(messageItem, "payload")) {
          const payload = JSON.parse(window.atob(messageItem.payload)) as THINGS.payloadItem[] | { action: string, thing_id: string }
          Reflect.set(messageItem, "payload", payload)
          socketThingList.push(messageItem as unknown as SCOKET.onMessageProps)
        }
      })
      state.socketThingList = socketThingList
    },
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  }
})

export default SocketSlice