/*
 * @Date: 2022-04-21 23:21:16
 * @LastEditors: Save
 * @LastEditTime: 2022-08-04 03:02:29
 * @FilePath: /src/store/index.ts
 * @Description: 
 */
import { configureStore, PayloadAction } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import { useDispatch, useSelector, useStore } from 'react-redux'
import rootReducer, { RootState, RootActions, RootActionsName } from './rootReducer'

const store = configureStore({
  reducer: rootReducer
})

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, PayloadAction<string>>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type AppStore = typeof store
export const useAppStore = () => useStore<AppStore>()
export const useAppSelector = <T extends keyof RootState>(name: T) => useSelector((state: RootState) => state[name])

export function clearRootStore() {
  for (const i in RootActions) {
    store.dispatch(RootActions[i as RootActionsName].clearInitState())
  }
}

export default store