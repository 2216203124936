/*
 * @Date: 2022-05-20 14:32:56
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-15 04:03:28
 * @FilePath: /kittens/src/store/slice/tree.slice.ts
 * @Description: 
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { arrayToTree } from "performant-array-to-tree";
import { getTreeListSort, removeItem, setFindIndex, setHasLeafAllFun, setIboxStatus, setProperty, setToggleLockGroups, setTreeToThingItem, setDeviceTypeStatus } from "@/assets/helper/tree.helper";
import { onDeleteThinggroups, onGetThinggroupsList, onGetThingrules, onPostCreateThinggroups, onPutThinggroupsParent } from "@/services/thing.group";
import { onGetThingList, onPostUpdateThingTree, onPutThingsGroup } from "@/services/things";
import store from "..";
import { getTemplateFormatList } from "@/assets/helper/thing.helper";
import cloneDeep from "lodash/cloneDeep";
import { DevicesType, TreeItemType, TreeSourceType } from "@/interface/enum";
import { onDeleteGroupsShareWithMe, onGetGroupsShareUserList } from "@/services/thing.share";

interface initState {
  // treeList: TREE.TreeItems,
  sourceData: TREE.ThingGroupList
  // loading: boolean
  updateCount: number
  ThingrulesList: any[]
  ThingList: any[]
  groupIdByArray: string[]
  groupsId: string
  treeListOps: TREE.treeListOps
  activeScenarios: TREE.treeListOpsName
  toggleLock: boolean
}

const initialState: initState = {
  // treeList: [],
  sourceData: [],
  // loading: false,
  updateCount: 0,
  ThingrulesList: [],
  ThingList: [],
  groupsId: "",
  groupIdByArray: [],
  activeScenarios: TreeSourceType.Owner,
  treeListOps: {
    owner: {
      loading: false,
      list: [],
      status: 0,
      groupIdByArray: []
    },
    sharedwith: {
      loading: false,
      list: [],
      status: 0,
      groupIdByArray: []
    },
    shared: {
      loading: false,
      list: [],
      status: 0,
      groupIdByArray: []
    },
  },
  toggleLock: false
}

const TreeSlice = createSlice({
  name: "treeSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    setDatafilter: (state) => {
      const { activeScenarios } = state
      state.treeListOps[activeScenarios].list = arrayToTree([], { id: "id", parentId: "parent_id", dataField: null }) as TREE.TreeItems
      // state.treeList = arrayToTree([], { id: "id", parentId: "parent_id", dataField: null }) as TREE.TreeItems
    },
    updateTreeList: (state, action: PayloadAction<TREE.TreeItems>) => {
      const { activeScenarios } = state
      state.treeListOps[activeScenarios].list = action.payload
    },
    removeItem: (state, action: PayloadAction<{items: TREE.TreeItems, id: string}>) => {
      const { items, id } = action.payload  
      const { activeScenarios } = state
      state.treeListOps[activeScenarios].list = removeItem(items, id)
      // state.treeList = removeItem(items, id)
    },
    setProperty: (state, action: PayloadAction<{id: string}>) => {
      const { id } = action.payload   
      const getProperty = setProperty(state.treeListOps[state.activeScenarios].list, id, 'collapsed', (value) => {
        return !value;
      })
      state.treeListOps[state.activeScenarios].list = getProperty
    },
    setRulesList: (state, action: PayloadAction<{ thingList: any, id: string }>) => {

    },
    AddGroupIdByArray: (state, action) => {
      const groupID: string = action.payload
      const { activeScenarios } = state
      if (!state.treeListOps[activeScenarios].groupIdByArray.includes(groupID)) {
        state.treeListOps[activeScenarios].groupIdByArray.push(groupID)
      }
    },
    // updateWsData: (state, action: PayloadAction<SCOKET.onMessageProps>) => {
    //   const { activeScenarios } = state
    //   if (action.payload) {
    //     setTreeToThingItem(state.treeListOps[activeScenarios].list, action.payload)
    //   }
    // },
    updateWsData: (state, action: PayloadAction<SCOKET.onMessageProps[]>) => {
      const { activeScenarios } = state
      const list = cloneDeep(state.treeListOps[activeScenarios].list)
      state.treeListOps[activeScenarios].list = setTreeToThingItem(list, action.payload)
    },
    toggleLockGroupsFun: (state, action: PayloadAction<boolean>) => {
      const { activeScenarios } = state
      setToggleLockGroups(state.treeListOps[activeScenarios].list, action.payload)
    },
    setToggleLock: (state, action: PayloadAction<boolean>) => {
      state.toggleLock = action.payload
    },
    clearInitState: () => {
      return cloneDeep(initialState)
    },
    updateActiveScenarios: (state, action: PayloadAction<TREE.treeListOpsName>) => {
      state.activeScenarios = action.payload
    },
    updateTreeListOpsLoading: (state, action: PayloadAction<boolean>) => {
      const { activeScenarios } = state
      state.treeListOps[activeScenarios].loading = action.payload
    },
    updateCount: (state) => {
      state.updateCount = state.updateCount += 1
    }
  },
  extraReducers: builder => {
    builder
      // get list
      .addCase(FETCH_THINGGROUPS_LIST.pending, (state, action) => {
        const { activeScenarios } = state
        state.treeListOps[activeScenarios].loading = true
      })
      .addCase(FETCH_THINGGROUPS_LIST.fulfilled, (state, action) => {
        const { list } = action.payload
        const { activeScenarios } = state
        //console.log('FETCH_THINGGROUPS_LIST', list)
        if (list.length) {
          const treeList = setFindIndex(arrayToTree(list, { id: "id", parentId: "parent_id", dataField: null }) as TREE.TreeItems)
          const setTreeListSort = getTreeListSort(treeList)
          state.treeListOps[activeScenarios].list = setTreeListSort
        }
        state.treeListOps[activeScenarios].loading = false
      })

      // load groups list and things list
      .addCase(FETCH_THINGGROUPS_AND_THING_LIST.pending, (state, action) => {
        const { source } = action.meta.arg.groupsParams.params
        if (source) {
          state.treeListOps[source].loading = true
        }
      })
      .addCase(FETCH_THINGGROUPS_AND_THING_LIST.fulfilled, (state, action) => {
        const { thingsArray, groupsArray } = action.payload
        const { source } = action.meta.arg.groupsParams.params
        //console.log('FETCH_THINGGROUPS_AND_THING_LIST', source)
        if (source) {
          if (groupsArray.length) {
            const setTreeList = state.treeListOps[source].list
            const getFlattenTreeListMap = FlattenTreeListFun(setTreeList)
            const getmergeListData = mergeListDataFun(groupsArray, getFlattenTreeListMap)
            const getArrayToTree = arrayToTree(getmergeListData, { id: "id", parentId: "parent_id", dataField: null }) as TREE.TreeItems
            const getHasLeafData = setHasLeafAllFun(getArrayToTree)
            const treeList = setFindIndex(getHasLeafData)
            const setTreeListSort = getTreeListSort(treeList)
            if (thingsArray.length) {
              thingsArray.forEach(thinsItem => {
                state.treeListOps[source].list = addThingListByTreeList(thinsItem.ids, setTreeListSort, thinsItem.data)
                if (thinsItem.data.length === 0) {
                  const groupIdIndex = state.treeListOps[source].groupIdByArray.indexOf(thinsItem.ids)
                  if (groupIdIndex !== -1) {
                    const localGroupsIdArray = cloneDeep(state.treeListOps[source].groupIdByArray)
                    localGroupsIdArray.splice(groupIdIndex, 1)
                    state.treeListOps[source].groupIdByArray = state.treeListOps[source].groupIdByArray.filter(groupsId => groupsId !== thinsItem.ids)
                  }
                }
              })
            } else {
              state.treeListOps[source].list = setTreeListSort
            }
            setToggleLockGroups(state.treeListOps[source].list, state.toggleLock)
            if (source === TreeSourceType.Owner) {
              state.treeListOps.owner.list = setIboxStatus(state.treeListOps.owner.list)
            }
          } else {
            state.treeListOps[source].list = []
          }
          state.treeListOps[source].loading = false
        }
      })
      .addCase(FETCH_THINGGROUPS_AND_THING_LIST.rejected, (state, action) => {
        const { source } = action.meta.arg.groupsParams.params
        if (source) {
          state.treeListOps[source].loading = false
        }
      })

      // create
      .addCase(FETCH_THINGGROUPS_CREATE.pending, () => {})
      .addCase(FETCH_THINGGROUPS_CREATE.fulfilled, () => {})

      // delete
      .addCase(FETCH_THING_AND_GROUPS_DELETE.pending, (state) => {
        const { activeScenarios } = state 
        state.treeListOps[activeScenarios].loading = true  
      })
      .addCase(FETCH_THING_AND_GROUPS_DELETE.fulfilled, (state, action) => {
        const { id } = action.payload 
        const { activeScenarios } = state   
        state.treeListOps[activeScenarios].list = removeItem(state.treeListOps[activeScenarios].list, id)
        state.treeListOps[activeScenarios].loading = false
        state.updateCount = state.updateCount += 1
      })

      // update
      .addCase(FETCH_PUT_THING_GROUPS_PARENT.pending, (state) => {
        const { activeScenarios } = state   
        state.treeListOps[activeScenarios].loading = true
      })
      .addCase(FETCH_PUT_THING_GROUPS_PARENT.fulfilled, (state, action) => {  
        const { activeScenarios } = state   
        state.treeListOps[activeScenarios].loading = false
        state.updateCount = state.updateCount += 1
      })

      // Thingrules list
      .addCase(FETCH_GET_THINGRULES.fulfilled, (state, action) => {
        state.ThingrulesList = action.payload.list
      })
      
      .addCase(FETCH_GET_THING_GROUPS_LIST.pending, (state, action) => {
        // const { groupIdByArray } = state
        // const groupID = action.meta.arg.params.groupID || ""
        // if (groupIdByArray.includes(groupID)) {
        //   // 重新刷新
        // } else {
        //   if (groupID) {
        //     state.groupsId = groupID
        //     state.groupIdByArray.push(groupID)
        //   }
        // }
      })
      .addCase(FETCH_GET_THING_GROUPS_LIST.fulfilled, (state, action) => {
        //('FETCH_GET_THING_GROUPS_LIST', action)
        const { id, list } = action.payload
        state.treeListOps[state.activeScenarios].list = addThingListByTreeList(id, state.treeListOps[state.activeScenarios].list, list)
      })
      .addCase(FETCH_GET_THING_GROUPS_LIST.rejected, (state, action) => {
        //console.log('FETCH_GET_THING_GROUPS_LIST.rejected =>', action)
      })
      // update thing 
      .addCase(FETCH_PUT_THING_GROUPS.pending, (state) => {
        const { activeScenarios } = state   
        state.treeListOps[activeScenarios].loading = true
      })
      .addCase(FETCH_PUT_THING_GROUPS.fulfilled, (state) => {
        // state.loading = false
        state.updateCount = state.updateCount += 1
      })
      .addCase(FETCH_POST_UPDATE_THINGS.pending, (state) => {
        const { activeScenarios } = state   
        state.treeListOps[activeScenarios].loading = true
      })
      .addCase(FETCH_POST_UPDATE_THINGS.fulfilled, (state) => {
        state.updateCount = state.updateCount += 1
      })
      .addCase(FETCH_DELETE_THING_GROUP_SHARE_WITH_ME.fulfilled, (state) => {
        state.updateCount = state.updateCount += 1
      })
  }
})

const FlattenTreeListFun = (treeList: TREE.TreeItems, newTreeMap: { [ id in string ]: Omit<TREE.TreeItem, "children"> } = {}) => {
  for (const item of treeList) {
    Reflect.set(newTreeMap, item.id, item)
    if (item.children.length) {
      FlattenTreeListFun(item.children, newTreeMap)
    }
  }
  return newTreeMap
}

const mergeListDataFun = (treeList: TREE.TreeItems, TreeMap: { [ id in string ]: Omit<TREE.TreeItem, "children"> } = {}) => {
  for (const item of treeList) {
    const oldItem = Reflect.get(TreeMap, item.id)
    if (oldItem) {
      item.collapsed = oldItem.collapsed
    }
  }
  return treeList
}

/**
 * 合并旧数据的内容，当前方法已弃用
 * @param treeList tree元数据
 * @param setTreeListSort API 数据
 * @returns 返回的新数据
 */
const mergeList = (treeList: TREE.TreeItems, setTreeListSort: TREE.TreeItems) => {
  if (!treeList.length) return setTreeListSort;
  for (const setTreeItem of setTreeListSort) {
    for (const treeIem of treeList) {
      if (treeIem.id === setTreeItem.id) {
        setTreeItem.collapsed = treeIem.collapsed
        if (treeIem.children.length && !setTreeItem.children.length) {
          setTreeItem.children = treeIem.children
          setTreeItem.has_leaf = treeIem.has_leaf
        } else if (treeIem.children.length && setTreeItem.children.length) {
          const devicesList: TREE.TreeItems = []
          treeIem.children.forEach(item => {
            if (item.typeStatus === TreeItemType.Sensor) {
              if (item.group_id !== item.parentId) {
                item.group_id = item.parentId
              }
              devicesList.push(item)
            }
          })
          if (devicesList.length) {
            setTreeItem.has_leaf = false
          }
          
          setTreeItem.children = [...mergeList(treeIem.children, setTreeItem.children), ...devicesList]
        }
      }
    }
  }
  return setTreeListSort
}

const addThingListByTreeList = (id: string, treeList: TREE.TreeItems, ThingList: any[]) => {
  for (const item of treeList) {
    if (id === item.id) {
      item.has_leaf = false
      if (item.children.length) {
        const groupsList: TREE.TreeItems = item.children.filter(item => item.typeStatus === TreeItemType.Groups)
        item.children = [
          ...groupsList,
          ...ThingList
        ]
      } else {
        item.children = ThingList
      }
      // console.log('ThingList', cloneDeep(ThingList))
      if (ThingList.length >= 20) {
        item.children.push({
          id: `${id}-loadMore`,
          children: [],
          typeStatus: TreeItemType.LoadMore
        })
      }
      break
    } else {
      if (item.children.length) {
        item.children = addThingListByTreeList(id, item.children, ThingList)
      }
    }
  }
  return treeList
}


// eslint-disable-next-line @typescript-eslint/no-redeclare
interface data {
  // group: TREE.ThingGroup
  // groupOneChild: TREE.ThingGroup | false
  // groupChilds: TREE.ThingGroupList
  parentId: string | undefined
  list: TREE.ThingGroupList
}
export const FETCH_THINGGROUPS_LIST = createAsyncThunk(
  "FETCH_THINGGROUPS_LIST", async (props: { params: TREE.thingGroupsListParams, thisData?: { id: string, parentId: string }}) => {
    const res = await onGetThinggroupsList(props.params)
    //console.log('FETCH_THINGGROUPS_LIST', res)
    const data: data = {
      parentId: props.params.parentId,
      list: []
    }
    if (res.success) {
      data.list = res.payload.list
    }
    return data
  }
)

const getThingList = async (data: TREE.ThingGroupList = [], params: TREE.thingGroupsListParams) => {
  const res = await onGetThinggroupsList(params)
  if (res.success) {
    data = res.payload.list.map(item => {
      item["source_has_leaf"] = item.has_leaf
      return item
    }) || []
  }
  return data
}

export const FETCH_THINGGROUPS_AND_THING_LIST = createAsyncThunk(
  "FETCH_THINGGROUPS_AND_THING_LIST", async (props: { groupsParams: { params: TREE.thingGroupsListParams }, thingsParams: { ids: string[] } }) => {
    const { thingsParams, groupsParams } = props
    // setp things 
    const thingsArray: { ids: string, data: THINGS.GetThingListItem[] }[] = []
    if (thingsParams.ids.length) {
      for await (const groupsId of thingsParams.ids) {
        const res = await onGetThingList({ pageSize: 20, current: 1, groupID: groupsId })
        const thingItemObject: {ids: string, data: THINGS.GetThingListItem[] } = { ids: groupsId, data: [] }
        if (res.success) {
          if (res.payload.list && res.payload.list.length && res.payload.list !== null) {
            const TemplateFormatList = getTemplateFormatList(res.payload.list, groupsId)
            thingItemObject.data = TemplateFormatList as unknown as THINGS.GetThingListItem[]
          }
          thingsArray.push(thingItemObject)
        }
      }
    }

    // setp groups
    let groupsArray: TREE.ThingGroupList = []
    groupsArray = await getThingList(groupsArray, groupsParams.params)
    if (groupsParams.params.source === TreeSourceType.Owner) {
      if (!groupsArray.length) {
        const FetchIboxRes = await onPostCreateThinggroups({ prev_id: "0", name: "iBox", next_id: ""  })
        if (FetchIboxRes.success) {
          groupsArray = await getThingList(groupsArray, groupsParams.params)
        }
      }
    }
    if (groupsParams.params.source === 'sharedwith') {
      for await (const group of groupsArray) {
        if (group.parent_id === "") {
          const shareUsersRes = await onGetGroupsShareUserList({ pageSize: 1, current: 1, total: 1, groupID: group.id})
          if (shareUsersRes.success){
            if (shareUsersRes.payload.list && shareUsersRes.payload.list.length && shareUsersRes.payload.list !== null) {
              group.sharer = shareUsersRes?.payload?.list[0]?.sharer
              group.share_perm = shareUsersRes?.payload?.list[0].perm
            }
          }
        }
      }
    }
    return {
      thingsArray,
      groupsArray
    }
  }
)

export const FETCH_THINGGROUPS_CREATE = createAsyncThunk(
  "FETCH_THINGGROUPS_CREATE", async (data: TREE.createThingGroup) => {
    const res = await onPostCreateThinggroups(data)
    return res
  }
)

export const FETCH_THING_AND_GROUPS_DELETE = createAsyncThunk(
  "FETCH_THING_AND_GROUPS_DELETE", async (props: { id: string, data?: THINGS.onUpdateThingReq}) => {
    if (props.data) {
      await onPutThingsGroup(props.id, props.data)
    } else {
      await onDeleteThinggroups(props.id)
    }
    
    return { id: props.id }
  }
)

export const FETCH_PUT_THING_GROUPS_PARENT = createAsyncThunk(
  "FETCH_PUT_THING_GROUPS_PARENT", async (props: { id: string, data: { parent_id: string, prev_id: string, next_id: string } }) => {
    await onPutThinggroupsParent(props.id, props.data)
    //console.log('FETCH_PUT_THING_GROUPS_PARENT', props)
    return true
  }
)

export const FETCH_GET_THINGRULES = createAsyncThunk(
  "FETCH_GET_THINGRULES", async () => {
    const res = await onGetThingrules({current: 1, pageSize: 10})
    if (res.success) {
      return res.payload
    }
  }
)

export const FETCH_GET_THING_GROUPS_LIST = createAsyncThunk(
  "FETCH_GET_THING_GROUPS_LIST", async (props: { params: THINGS.GetQueryThingList, parent_path: string }) => {
    const res = await onGetThingList(props.params)
    const data: { id: string, list: any } = {
      id: props.params.groupID || "",
      list: []
    }    
    if (res.success) {
      if (res.payload?.list && res.payload?.list !== null) {
        try {
          const TemplateFormatList = getTemplateFormatList(res.payload.list, data.id)
          data.list = setDeviceTypeStatus(TemplateFormatList, DevicesType.Sensor)
        } catch (error) {
          console.log('error', error)
        }
      }
    }
    return data
  }
)

export const FETCH_PUT_THING_GROUPS = createAsyncThunk(
  "FETCH_PUT_THING_GROUPS", async (props: { id: string, data: THINGS.onUpdateThingReq, getThing: { params: THINGS.GetQueryThingList, parent_path: string } | false }) => {
    const res = await onPutThingsGroup(props.id, props.data)
    //console.log('FETCH_PUT_THING_GROUPS', res)
    if (props.getThing) {
      store.dispatch(
        FETCH_GET_THING_GROUPS_LIST({
          params: { groupID: props.getThing.params.groupID, pageSize: 20, current: 1 },
          parent_path: props.getThing.parent_path
        })
      )
    }
    
    if (res.success) {
      return res.payload
    }
  }
)

export const FETCH_DELETE_THING_GROUP_SHARE_WITH_ME = createAsyncThunk(
  "FETCH_DELETE_THING_GROUP_SHARE_WITH_ME", async (group_id: string) => {
    const res = await onDeleteGroupsShareWithMe(group_id)
    if (res.success) {
      return res.payload
    }
  }
)

export const FETCH_POST_UPDATE_THINGS = createAsyncThunk(
  "FETCH_POST_UPDATE_THINGS", async (items: any) => {
    const res = await onPostUpdateThingTree(items)
    if (res.success) {
      return res.payload
    }
  }
)


export default TreeSlice;
