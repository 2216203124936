/*
 * @Date: 2022-07-21 18:49:03
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-18 16:02:24
 * @FilePath: /kittens/src/assets/helper/thing.type.helper.ts
 * @Description: 
 */

import { PublishType, WsAlertActionsType } from "@/interface/enum"
import { isMobileGateway } from "../utils/base"
import cloneDeep from "lodash/cloneDeep";
import { batteryAndSignalFilledFun, getPayloadReturn, templateListFun } from "./thing.helper"


export const setChannels = (channels: string) => {
  try {
    return JSON.parse(channels)
  } catch (error) {
    return []
  }
}

export const mergeData = (data: THINGS.GetThingListItem[]) => {
  for (const item of data) {
    item.type.channels = setChannels(item.type.channels)
  }
  return data
}

export const mergeSocketAndThingListData = (ThingList: THINGS.thingItem[], socketList: SCOKET.onMessageProps[]) => {
  const StateList = cloneDeep(ThingList)
  for (let item of StateList) {
    // 5g gateways no socket now (may have in the future)
    if (!isMobileGateway(item?.gateway)) {
      mergeSocketItemToThingItem(item, socketList)
    }
  }
  return StateList
}

export const mergeSocketItemToThingItem = (item: THINGS.thingItem, socketList: SCOKET.onMessageProps[]) => {
  const FindSocketItem = socketList.find(socketItem => socketItem?.thing_id === item.source.thing_id)
  if (FindSocketItem) {
    switch (FindSocketItem.publish_type) {
      case PublishType.LATEST:
        const { templateList, defaultList } = getPayloadReturn(FindSocketItem.payload)
        if (templateList.length) {
          item.templateFormat.payload = templateListFun(templateList, [...item.templateFormat.channels, ...item.templateFormat.payload_channels])
          item.templateFormat.defaultStatus = batteryAndSignalFilledFun(defaultList)
        }
      break
      case PublishType.ALERT:
        if (FindSocketItem.payload.action === "Online") {
          item.templateFormat.status = "Online"
        } else if (FindSocketItem.payload.action === "Offline") {
          item.templateFormat.status = "Offline"
        } else if ([WsAlertActionsType.Enter, WsAlertActionsType.Leave].includes(FindSocketItem.payload.action)) {
          const payload: SCOKET.payloadSwitchObject = FindSocketItem.payload
          if (payload.extra && payload.extra !== null) {
            const extra: { alert_type: string, channel_id: number } = JSON.parse(payload.extra)
            item.templateFormat.payload = item.templateFormat.payload.map(mapItem => {
              if (mapItem.key === extra.channel_id) {
                console.log('payload', payload)
                if (FindSocketItem.payload.action === WsAlertActionsType.Enter) {
                  mapItem["color"] = "red"
                  mapItem['type'] = true
                  mapItem["status"] = WsAlertActionsType.Enter
                } else {
                  mapItem["color"] = ""
                  mapItem["status"] = WsAlertActionsType.Leave
                  mapItem['type'] = false
                }
              }
              return mapItem
            }) 
          }
        }
      break
    }
    item.templateFormat.update_date = new Date().toString()
  }
  return item
}
