/*
 * @Date: 2021-12-23 22:08:16
 * @LastEditors: Save
 * @LastEditTime: 2022-04-24 22:53:25
 * @FilePath: /src/assets/utils/date.helper.ts
 * @Description:
 */
import moment, { QUnitType, OpUnitType } from "dayjs"

export function isDate(dateMsg: string) {
  return moment(dateMsg).isValid();
}

export function localDate(dateMsg: moment.ConfigType, format: string = 'YYYY-MM-DD HH:mm') {
  return moment(dateMsg).format(format);
}

export function localShortDate(dateMsg: moment.ConfigType, format: string = 'MM-DD HH:mm') {
  return moment(dateMsg).format(format);
}

export function localDateDiff(date: moment.ConfigType, unitOfTime?: QUnitType | OpUnitType) {
  return moment().diff(moment(date), "minutes")
}

export function localDateUnix(unix: number) {
  return moment.unix(unix)
}

export default moment