import { Spin } from "antd"
import { useWindowSize } from "rooks"

const RouteLoading = () => {
  const { innerHeight, innerWidth } = useWindowSize()
  return (
    <div style={{ width: innerWidth as number, height: (innerHeight as number) / 1.5, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Spin size="large" />
    </div>
  )
}

export default RouteLoading
