import { API_URL_HELIUM, BASE_URL_HELIUM } from '@/api/api.helium';
import { ApiUrlGateway } from '@/api/api.url';
import { GlobalAxios } from '@/api/http';
import { SpeedTestTier } from '@/interface/enum';
import moment from '@/assets/utils/date.helper';
import type { Key } from 'react';

export function postCreateUser(data: GATEWAY.CreateUserResData) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlGateway.CreateUser },
    alertMsg: 'create user'
  }).fetch<GATEWAY.CreateUserReqBody>('POST', data);
}

export function getRole() {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlGateway.Role },
    alertMsg: 'role'
  }).fetch<{ list: string[] }>('GET');
}

export function getGateway(params: GATEWAY.QueryGetResParams) {
  if (params.hid) params.hid = params.hid.toUpperCase();
  const id = params.pubkey ? params.pubkey : params.hid;
  return new GlobalAxios({ 
    axiosOptions: { url: ApiUrlGateway.Get.replace('{{id}}', id || 'UNKNOWN') },
    alertMsg: 'gateway'
  }).fetch<GATEWAY.GatewayListItem>('GET', params);
}

export function getGatewayList(params: GATEWAY.QueryListResParams) {
  delete params.total;
  if (params.hid) params.hid = params.hid.toUpperCase();
  const url = params.source === 'all_latests' ? ApiUrlGateway.gatewayLatests : ApiUrlGateway.List;
  return new GlobalAxios({ 
    axiosOptions: { url },
    alertMsg: 'gateway list'
  }).fetch<GATEWAY.GatewayListReqBody>('GET', params);
}

export function postCreateGateway(data: GATEWAY.BatchCreateGateways) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlGateway.List },
    alertMsg: 'create gateway'
  }).fetch('POST', data);
}

export function deleteGatewayListItem(id: string) {
  return new GlobalAxios({
    axiosOptions: { url: `${ApiUrlGateway.List}/${id}` },
    alertMsg: 'delete'
  }).fetch('DELETE');
}

export function deleteGatewayListIds(ids: Key[]) {
  return new GlobalAxios({
    axiosOptions: { url: `${ApiUrlGateway.Delbatch}` },
    alertMsg: 'delete gateway ids'
  }).fetch('DELETE', { gateway_ids: ids });
}

export function getGatewayListItem(id: string) {
  return new GlobalAxios({
    axiosOptions: { url: `${ApiUrlGateway.List}/${id}` },
    alertMsg: 'single gateway by id'
  }).fetch<GATEWAY.GatewayListItem>('GET');
}

export function getGatewayLatestDetails(id: string) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlGateway.Latest.replace('{{id}}', id) },
    alertMsg: 'Latest Details'
  }).fetch<GATEWAY.GatewayLatestReqBody>('GET');
}

export function patchHost(id: string, user_id: string) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlGateway.Host.replace('{{id}}', id) },
    alertMsg: 'change geteway host'
  }).fetch('PATCH', { host: user_id });
}

export async function getMakerList() {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlGateway.makerList },
    alertMsg: 'makers list'
  }).fetch('GET');
}

export function getGatewayDownlink(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlGateway.gatewaysDownlink.replace('{{id}}', id),
    },
    alertMsg: "Get gateway downlink by id or hid or pubkey",
    toastFlag: false
  }).fetch<GATEWAY.GatewayDownlinkBody>('GET');
}

export function postGatewayDownlink(id: string, command: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlGateway.gatewaysDownlink.replace('{{id}}', id)
    },
    alertMsg: "Update gateway downlink by id or hid or pubkey",
    toastFlag: false
  }).fetch('POST', { command });
}

export function deleteGatewayDownlink(id: string) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlGateway.gatewaysDownlink.replace('{{id}}', id) },
    alertMsg: "Cancel gateway downlink by id or hid or pubkey",
  }).fetch('DELETE');
}


const toBps = (mbps: number) => mbps * 125000;

const getDownloadTier = (cellSpeedtest: GATEWAY.CellSpeedtest) => {
  const speed = cellSpeedtest?.downloadSpeed;
  if (speed === undefined) return undefined;

  if (speed >= toBps(100)) {
    return SpeedTestTier.ACCEPTABLE;
  } else if (speed >= toBps(50)) {
    return SpeedTestTier.DEGRADED;
  } else if (speed >= toBps(30)) {
    return SpeedTestTier.POOR;
  }
  return SpeedTestTier.FAIL;
};

const geteUploadTier = (cellSpeedtest: GATEWAY.CellSpeedtest) => {
  const speed = cellSpeedtest?.uploadSpeed;
  if (speed === undefined) return undefined;

  if (speed >= toBps(10)) {
    return SpeedTestTier.ACCEPTABLE;
  } else if (speed >= toBps(5)) {
    return SpeedTestTier.DEGRADED;
  } else if (speed >= toBps(2)) {
    return SpeedTestTier.POOR;
  }
  return SpeedTestTier.FAIL;
};

const getLatencyTier = (cellSpeedtest: GATEWAY.CellSpeedtest) => {
  const latency = cellSpeedtest?.latency;
  if (latency === undefined) return undefined;

  if (latency <= 50) {
    return SpeedTestTier.ACCEPTABLE;
  } else if (latency <= 75) {
    return SpeedTestTier.DEGRADED;
  } else if (latency <= 100) {
    return SpeedTestTier.POOR;
  }
  return SpeedTestTier.FAIL;
};

const getSpeedTestTier = (
  cellSpeedtest: GATEWAY.CellSpeedtest,
  downloadTier: SpeedTestTier | undefined,
  latencyTier: SpeedTestTier | undefined,
  uploadTier: SpeedTestTier | undefined,
): SpeedTestTier | 'N/A' => {
  if (!cellSpeedtest) return 'N/A';

  if (
    downloadTier === SpeedTestTier.FAIL ||
    uploadTier === SpeedTestTier.FAIL ||
    latencyTier === SpeedTestTier.FAIL
  ) {
    return SpeedTestTier.FAIL;
  } else if (
    downloadTier === SpeedTestTier.POOR ||
    uploadTier === SpeedTestTier.POOR ||
    latencyTier === SpeedTestTier.POOR
  ) {
    return SpeedTestTier.POOR;
  } else if (
    downloadTier === SpeedTestTier.DEGRADED ||
    uploadTier === SpeedTestTier.DEGRADED ||
    latencyTier === SpeedTestTier.DEGRADED
  ) {
    return SpeedTestTier.DEGRADED;
  }
  return SpeedTestTier.ACCEPTABLE;
};

const getRewardScale = (tier: SpeedTestTier | 'N/A') => {
  switch (tier) {
    case SpeedTestTier.ACCEPTABLE:
      return '1.00';
    case SpeedTestTier.DEGRADED:
      return '0.50';
    case SpeedTestTier.POOR:
      return '0.25';
    default:
    case 'N/A':
    case SpeedTestTier.FAIL:
      return '0.00';
  }
};

export async function getGatewayMobileRadios(pubkey: string) {
  let mobile: GATEWAY.Mobile = {
    updated_at: 0
  };
  try {
    const setpOne = await new GlobalAxios({
      axiosOptions: {
        baseURL: BASE_URL_HELIUM,
        url: API_URL_HELIUM.hotspotcells.replace("{pubkey}", pubkey),
        timeout: 10000
      }
    }).fetch("GET")
    if (setpOne.success) {
      Reflect.set(mobile, "cells", setpOne.payload)
      if (!mobile.cells?.length) return;
      const setpTwo = await new GlobalAxios({
        axiosOptions: {
          baseURL: BASE_URL_HELIUM,
          url: API_URL_HELIUM.hotspotsAvgSpeedtest.replace("{pubkey}", pubkey),
          timeout: 10000
        }
      }).fetch("GET")
      if (setpTwo.success) {
        const cellSpeedtest = {
          downloadSpeed: setpTwo.payload?.downloadSpeedAvgBps,
          uploadSpeed: setpTwo.payload?.uploadSpeedAvgBps,
          latency: setpTwo.payload?.latencyAvgMs,
          timestamp: setpTwo.payload?.timestamp,
          rewardMultiplier: setpTwo.payload?.rewardMultiplier,
          validity: setpTwo.payload?.validity,
        };

        Reflect.set(mobile, "cellSpeedtest", cellSpeedtest)
        Reflect.set(mobile, "downloadTier", getDownloadTier(cellSpeedtest))
        Reflect.set(mobile, "uploadTier", geteUploadTier(cellSpeedtest))
        Reflect.set(mobile, "latencyTier", getLatencyTier(cellSpeedtest))
        Reflect.set(mobile, "speedTestTier", getSpeedTestTier(
          cellSpeedtest,
          mobile.downloadTier,
          mobile.uploadTier,
          mobile.latencyTier,
        ))
        if (setpTwo.payload?.rewardMultiplier) {
          mobile.rewardScale = setpTwo.payload?.rewardMultiplier;
          Reflect.set(mobile, "rewardScale", setpTwo.payload?.rewardMultiplier)
        } else {
          Reflect.set(mobile, "rewardScale", getRewardScale(mobile.speedTestTier))
        }

        const now = new Date()
        now.setUTCHours(0, 0, 0, 0)
        const maxTime = moment(now).add(-25, 'hours').format('YYYY-MM-DD')
        const minTime = moment(now).add(-49, 'hours').format('YYYY-MM-DD')
        const setpThree = await new GlobalAxios({
          axiosOptions: {
            baseURL: BASE_URL_HELIUM,
            url: `${API_URL_HELIUM.hotspotRewards.replace("{pubkey}", pubkey)}?max_date=${maxTime}&min_date=${minTime}`,
            timeout: 10000
          }
        }).fetch("GET")

        if (setpThree.success) {
          if (setpThree.payload) {
            Reflect.set(mobile, "rewards", setpThree.payload);
          }
        }
      }
    } else {
      Reflect.set(mobile, "cells", [])
      Reflect.set(mobile, "error", 'Unable to get radio data')
    }
  } finally {
    return mobile;
  }
}

export function attachEarnings(mobile: any, earnings: any) {
  if (!mobile || !earnings || !mobile.cells) return

  let min = 0;
  let sum = 0;
  let cnt = 0;
  for (let j = 0; j < mobile.cells.length; j++) {
    if (mobile.cells[j]) {
      min =
        min === 0
          ? mobile.cells[j]?.timestamp
          : Math.min(mobile.cells[j]?.timestamp, min);
      if (earnings) {
        const category = mobile.cells[j]?.cbsdId.match(/\d{1}AG\d{2}(MBS).*/)
        ? 'C'
        : mobile.cells[j]?.cbsdCategory;

        Reflect.set(mobile.cells[j], "earning", earnings[category]);
        Reflect.set(mobile.cells[j], "active", mobile.cells[j]?.operationMode);
        if (
          moment().diff(moment(mobile.cells[j]?.timestamp * 1000), 'minutes') <=
            1440 &&
            mobile.cells[j]?.operationMode
        ) {
          if (earnings[category])
            sum += earnings[category].tokens;
        } else {
          cnt++;
        }
      }
    }
  }
  Reflect.set(mobile, "updated_at", min * 1000);
  Reflect.set(mobile, "tokens", sum);
  Reflect.set(mobile, "down_radios", cnt);
}

