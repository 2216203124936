import axios, { Axios } from 'axios';
import { stringify } from 'qs';
import type { AxiosRequestConfig, Method, AxiosResponse, AxiosError } from 'axios';
import storageType from '@/type/storage.type';
import { message } from 'antd';
import store from '@/store';
import InitState from '@/store/slice/init.state.slice'
import { envConfig } from './api.url';

class GlobalAxios extends Axios {
  protected RequestConfig: AxiosRequestConfig;
  protected alertMsg: string;
  protected toastFlag: boolean;
  protected networkFlag: boolean = true;
  protected errorFlag: boolean = true;

  constructor(props: { axiosOptions: AxiosRequestConfig, alertMsg?: string, toastFlag?: boolean}) {
    const { axiosOptions, alertMsg = "", toastFlag = true } = props
    super(axiosOptions)
    this.RequestConfig = this.config(axiosOptions);
    this.alertMsg = alertMsg;
    this.toastFlag = toastFlag;
  }

  protected config(obj: AxiosRequestConfig) {
    const headers = obj.headers || {};
    const data: AxiosRequestConfig = {
      headers: {
        Accept: 'application/json',
        DeviceType: 'web',
        ...headers,
      },
      baseURL: envConfig.REACT_APP_BASE_URL,
      // timeout: 8000,
      paramsSerializer: (params) => {
        return stringify(params);
      },
      ...obj,
    };
    return data;
  }

  protected axiosError(error: AxiosError) {
    if (!!error.response && error.response.status !== 404) {
      if (error.response.data.error.id === 'com.perm.invalid_token') {
        localStorage.removeItem(storageType.TOKEN_DATA);
        store.dispatch(InitState.actions.setPathname("/user/login"))
      }
      if (this.toastFlag) {
        if (error.response.data.error.detail) {
          message.error(error.response?.data.error.detail);
        }
      }
    }
    // console.log(`Error ${this.alertMsg} =>`, error)

    return error
  }

  protected async http<P, R>(config: AxiosRequestConfig): Promise<API.RequestAPI<P, AxiosError>> {
    const res: AxiosResponse<API.RequestAPI<P, AxiosError>, R> = await axios(config);
    //  console.log(`${config.method}---${this.alertMsg} Api---Return the result:`, {...res});
    if (res.data.success) {
      return { success: true, payload: res.data.payload };
    } else {
      return Promise.reject(this.axiosError(res.data.error))
    }
  }

  async fetch<P = any, R = any>(method?: Method, data?: R): Promise<API.RequestAPI<P, AxiosError>>;
  async fetch<P, R>(method: Method = 'GET', data?: R): Promise<API.RequestAPI<P, AxiosError>> {
    return this.mergeConfig<P, R>(method, data);
  }

  protected async mergeConfig<P, R>(method: Method, data?: R): Promise<API.RequestAPI<P, AxiosError>> {
    const config = GlobalAxios.HeaderConfig({
      ...this.RequestConfig,
      method,
      ...(() => {
        if (method.toLocaleLowerCase() === 'get') {
          return { params: data };
        }
        return { data };
      })(),
    });
    // console.log(`${method}---${this.alertMsg} Api---Request configuration item`, config);
    return await this.http<P, R>(config);
  }

  // 添加新的 header
  static HeaderConfig(req: AxiosRequestConfig) {
    if (envConfig.REACT_APP_BASE_URL === req.baseURL) {
      if (req.headers) {
        const tokenString = localStorage.getItem(storageType.TOKEN_DATA);
        let tokenData: USER.LoginReqBody = {
          access_token: '',
          expires_at: Number(),
          token_type: '',
        };
        if (tokenString) {
          tokenData = JSON.parse(tokenString);
        }
        req.headers.Authorization = `${tokenData.token_type} ${tokenData.access_token}`;
      }
    }
    return req;
  }

  static async externalFetch(Url: string, init?: RequestInit | undefined) {
    try {
      const req = await fetch(Url, init);
      if (req.ok) {
        return req.json();
      }
    } catch (error) {
      return false;
    }
  }

}

axios.interceptors.response.use(
  async (config) => {
    try {
      if (config.status === 200) {
        config.data = {
          payload: config.data,
          success: true,
        };
      }
    } catch (error) {
      // console.log(error);
    } finally {
      return config;
    }
  },
  (error) => {
    return { data: { success: false, error } };
  },
);

export { GlobalAxios };
