/*
 * @Date: 2022-04-21 23:21:51
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-11-01 12:07:32
 * @FilePath: /kittens/src/store/rootReducer.ts
 * @Description: 
 */

import { combineReducers } from '@reduxjs/toolkit';
// slice
import InitStateSlice from './slice/init.state.slice'
import HomeSlice from './slice/home.slice'
import MakersSlice from './slice/makers.slice';
import UserSlice from './slice/user.slice'
import JsonRuleFormSlice from './slice/json.rule.form'
import QrSlice from './slice/qr.slice';
import TreeSlice from './slice/tree.slice'
import ThingsSlice from './slice/thing.slice';
import SocketSlice from './slice/socket.slice'
import EarningsSlice from './slice/earnings.slice';
import WalletsSlice from './slice/wallets.slice';
import GetewaySlice from './slice/geteway.slice';
import ThingTypesSlice from './slice/thing.type.slice';
import ArticleSlice from './slice/article.slice';

const rootReducer = combineReducers({
  InitStateSlice: InitStateSlice.reducer,
  HomeSlice: HomeSlice.reducer,
  MakersSlice: MakersSlice.reducer,
  UserSlice: UserSlice.reducer,
  JsonRuleFormSlice: JsonRuleFormSlice.reducer,
  QrSlice: QrSlice.reducer,
  TreeSlice: TreeSlice.reducer,
  ThingsSlice: ThingsSlice.reducer,
  SocketSlice: SocketSlice.reducer,
  EarningsSlice: EarningsSlice.reducer,
  WalletsSlice: WalletsSlice.reducer,
  GetewaySlice: GetewaySlice.reducer,
  ThingTypesSlice: ThingTypesSlice.reducer,
  ArticleSlice: ArticleSlice.reducer
})

export type RootState = ReturnType<typeof rootReducer>
export type RootActionsName = "InitStateSlice" | "HomeSlice" | "MakersSlice" | "UserSlice" | "JsonRuleFormSlice" | "QrSlice" | "TreeSlice" | "ThingsSlice" | "SocketSlice" | "EarningsSlice" | "WalletsSlice" | "GetewaySlice" | "ThingTypesSlice" | "ArticleSlice"
export const RootActions = {
  InitStateSlice: InitStateSlice.actions,
  HomeSlice: HomeSlice.actions,
  MakersSlice: MakersSlice.actions,
  UserSlice: UserSlice.actions,
  JsonRuleFormSlice: JsonRuleFormSlice.actions,
  QrSlice: QrSlice.actions,
  TreeSlice: TreeSlice.actions,
  ThingsSlice: ThingsSlice.actions,
  SocketSlice: SocketSlice.actions,
  EarningsSlice: EarningsSlice.actions,
  WalletsSlice: WalletsSlice.actions,
  GetewaySlice: GetewaySlice.actions,
  ThingTypesSlice: ThingTypesSlice.actions,
  ArticleSlice: ArticleSlice.actions
}

export default rootReducer
