/*
 * @Date: 2022-05-27 12:07:54
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-06 00:32:06
 * @FilePath: /src/services/thing.group.ts
 * @Description: 
 */
import { ApiUrlThingGroup, ApiUrlThingrules } from '@/api/api.url';
import { GlobalAxios } from '@/api/http';

export async function onGetThinggroupsList(params: TREE.thingGroupsListParams) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingGroup.thinggroups
    },
    alertMsg: "Tree List"
  }).fetch<{list: TREE.ThingGroupList}>("GET", params)
}

export async function onPostCreateThinggroups(data: Partial<TREE.createThingGroup>) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingGroup.thinggroups
    },
    alertMsg: "创建 Tree Item"
  }).fetch<TREE.createThingGroupResBody>("POST", data)
}

export async function onDeleteThinggroups(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingGroup.thinggroups}/${id}`
    },
    alertMsg: "删除单条 Tree"
  }).fetch<{data: TREE.createThingGroupResBody}>("DELETE")
}

export async function onPutThinggroupsParent(id: string, data: { parent_id: string, prev_id: string, next_id: string }) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingGroup.thinggroupsParent.replace("{id}", id)}`
    },
    alertMsg: "更新 Tree 位置"
  }).fetch<{data: TREE.createThingGroupResBody}>("PUT", data)
} 

export async function onPutThinggroups(id: string, data: Partial<TREE.updateThingGroup>) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingGroup.thinggroups}/${id}`
    },
    alertMsg: "Update thinggroup by id"
  }).fetch<{data: TREE.createThingGroupResBody}>("PUT", data)
}

export async function onGetThingrules(params: any) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingrules.thingrules
    },
    alertMsg: "获取 ThingRules List"
  }).fetch("GET", params)
}

export async function onGetThingGroups(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingGroup.thinggroups}/${id}`
    },
    alertMsg: "Get single thing group by id"
  }).fetch<TREE.createThingGroupResBody>("GET")
}

export async function onPostThingGroupsAuthorizeds(data: TREE.onPostAddShareAuthorizeds) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingGroup.thinggroupauthorizeds
    },
    alertMsg: "Batch create thing group authorized"
  }).fetch("POST", data)
}

export async function onPostInitThingGroup() {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingGroup.getInit
    },
    alertMsg: "Initialize thing group default data"
  }).fetch("POST")
}