/*
 * @Date: 2022-04-25 14:57:08
 * @LastEditors: Save
 * @LastEditTime: 2022-04-28 01:25:32
 * @FilePath: /src/services/wallet.ts
 * @Description: 
 */

import { ApiUrlwallets } from "@/api/api.url";
import { GlobalAxios } from "@/api/http";

export async function onGetWallets(params: BASE.ListQuery = { current: 1, pageSize: 100 }) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlwallets.wallets },
    alertMsg: 'Wallets'
  }).fetch<any>('GET', params);
}
