import cloneDeep from "lodash/cloneDeep";
import { GatewayCardLoadingStatus } from "@/interface/enum";
import { getGatewayMobileRadios } from "@/services/gateway";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


const initState: GATEWAY.initIState = {
  gatewayMap: {}
}
const GetewaySlice = createSlice({
  name: "GetewaySlice",
  initialState: cloneDeep(initState),
  reducers: {
    clearInitState: () => {
      return cloneDeep(initState)
    }
  },
  extraReducers: builder => {
    builder.addCase(FETCH_GET_GETEWAY_MOBILE_READIOS.pending, (state, action) => {
      state.gatewayMap[action.meta.arg] = { loading: GatewayCardLoadingStatus.pending }
    })
    builder.addCase(FETCH_GET_GETEWAY_MOBILE_READIOS.fulfilled, (state, action) => {
      const gatewayMap = {...state.gatewayMap}
      gatewayMap[action.meta.arg] = {
        loading: GatewayCardLoadingStatus.fulfilled,
        latest_status: action.payload
      }
      state.gatewayMap = gatewayMap
    })
    builder.addCase(FETCH_GET_GETEWAY_MOBILE_READIOS.rejected, (state, action) => {
      state.gatewayMap[action.meta.arg] = { loading: GatewayCardLoadingStatus.rejected }
    })
  }
})

export const FETCH_GET_GETEWAY_MOBILE_READIOS = createAsyncThunk(
  "FETCH_GET_GETEWAY_MOBILE_READIOS", async (pubkey: string) => {
    const res = getGatewayMobileRadios(pubkey)
    return res
  }
)

export default GetewaySlice