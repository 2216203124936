/*
 * @Date: 2022-07-15 14:33:05
 * @LastEditors: Save
 * @LastEditTime: 2022-07-21 15:07:18
 * @FilePath: /src/services/thing.type.ts
 * @Description: 
 */

import { ApiUrlChannel, ApiUrlThingType } from '@/api/api.url';
import { GlobalAxios } from '@/api/http';


export async function onGetThingTypes(params: BASE.ListQuery) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingType.types
    },
    alertMsg: "query types"
  }).fetch("GET", params)
}

export async function onGetThingTypeDetails(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingType.types}/${id}`
    },
    alertMsg: "get single sensor type by id"
  }).fetch<THING_TYPE.DetailsReq>("GET")
}

export async function onPostThingTypeCreate(data: THING_TYPE.CreateReq) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingType.types
    },
    alertMsg: "create sensor type"
  }).fetch("POST", data)
}

export async function onPutThingTypeUpdate(id: string, data: THING_TYPE.CreateReq) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingType.types}/${id}`
    },
    alertMsg: "update sensor type by id"
  }).fetch<THING_TYPE.DetailsReq>("PUT", data)
}

export async function onDeleteThingTypeDetails(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingType.types}/${id}`
    },
    alertMsg: "delete single sensor type by id"
  }).fetch<THING_TYPE.DetailsReq>("DELETE")
}

export async function onGetThingChannelType() {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlChannel.channelType
    },
    alertMsg: "get channel type json file"
  }).fetch("GET")
}