import { HeliumIoApi, HeliumConsoleApi } from "@/api/api.external";
import { API_URL_HELIUM, BASE_URL_HELIUM } from "@/api/api.helium";
import { GlobalAxios } from "@/api/http";
import { localDate } from "@/assets/utils/date.helper";

interface getBody {
  cursor: string;
  data: any[];
}
// TODO 回调问题
async function getHotspotsList() {
  return new GlobalAxios({
    axiosOptions: { 
      url: API_URL_HELIUM.hotspots,
      baseURL: BASE_URL_HELIUM
    },
    alertMsg: 'hotspots list'
  })
  .fetch<getBody>('GET')
  .then((res) => {
    if (res.success) {
      const arr: any[] = [];
      res.payload.data.forEach((item) => {
        item.gain = `${item.gain / 10} dBi`;
        item.name = ((name: string) => {
          let str: string = '';
          name.split('-').forEach((msg) => {
            str += `${msg.replace(msg[0], msg[0].toLocaleUpperCase())} `;
          });
          return str;
        })(item.name);
        arr.push(item);
      });
      return {
        data: arr,
        success: true,
        total: arr.length,
      };
    }
    return {
      data: [],
      success: false,
      total: 0,
    };
  });
}

async function getMakersList() {
  return new GlobalAxios({
    axiosOptions: {
      url: API_URL_HELIUM.makers,
      baseURL: BASE_URL_HELIUM,
    },
    alertMsg: 'MAKERS LSIT'
  })
  .fetch<any[]>('GET')
  .then((res) => {
    if (res.success) {
      return res.payload;
    }
    return [];
  });
}

async function getCitiesList() {
  return new GlobalAxios({
    axiosOptions: { 
      url: API_URL_HELIUM.cities,
      baseURL: BASE_URL_HELIUM
    },
    alertMsg: 'CITIES LSIT'
  })
  .fetch<getBody>('GET', { order: 'hotspot_count' })
  .then((res) => {
    if (res.success) {
      return res.payload.data;
    }
    return [];
  });
}

async function getMetricsHotspotsData() {
  const body: HELIUM.MetricsHotspotsChart = {
    hotspots_count: [],
    online_pct: [],
    // owners_count: [],
    cities_count: [],
    countries_count: [],
  };
  return new GlobalAxios({
    axiosOptions: {
      url: API_URL_HELIUM.metricsHotspots,
      baseURL: BASE_URL_HELIUM,
    },
    alertMsg: 'Metrics Hotspots Data'
  })
  .fetch<HELIUM.MetricsHotspotsBody>('GET')
  .then((res) => {
    try {
      if (res.success) {
        const { count, onlinePct, citiesCount, countriesCount } = res.payload;
        const Newbody = {
          hotspots_count: count,
          online_pct: onlinePct,
          // owners_count: ownersCount,
          cities_count: citiesCount,
          countries_count: countriesCount,
        };
        const keys = Reflect.ownKeys(Newbody) as HELIUM.MetricsHotspotsChartKeys;
        keys.forEach((key) => {
          Newbody[key].forEach((item) => {
            body[key].push({
              ...item,
              timestamp: localDate(item.timestamp, 'MM-DD'),
            });
          });
        });
      }
    } finally {
      return body;
    }
  });
}

async function getOraclePricesList() {
  const oraclePricesList: { Date: string; scales: number }[] = [];
  try {
    const res = await fetch(HeliumIoApi.oraclePrices);
    if (res.status === 200) {
      const body: HELIUM.oraclePricesBody = await res.json();
      body.data.forEach((item) => {
        oraclePricesList.unshift({
          // Date: localDate(item.timestamp, 'MM-DD HH:mm'),
          Date: item.timestamp,
          scales: Number((item.price / 100000000).toFixed(2)),
        });
      });
      return oraclePricesList;
    }
  } finally {
    return oraclePricesList;
  }
}

async function getHeliumStats() {
  try {
    const req = await fetch(HeliumIoApi.stats);
    if (req.status === 200) {
      const body = await req.json();
      return body.data;
    }
  } catch {
    return false;
  }
}

async function getMakerDetails(pubKey: string) {
  try {
    const req = await fetch(`${HeliumIoApi.maker}${pubKey}`);
    if (req.status === 200) {
      const body = await req.json();
      return body.data;
    }
  } catch (error) {
    return false;
  }
}

async function getMakerList() {
  try {
    const req = await fetch(HeliumIoApi.maker);
    if (req.status === 200) {
      const body = await req.json();
      return body.data;
    }
  } catch (error) {
    return false;
  }
}

async function postAddDevice() {
  try {
    const req = await fetch(HeliumConsoleApi.devices);
    if (req.status === 200) {
      const body = await req.json();
      return body.data;
    }
  } catch (error) {
    return false;
  }
}

export {
  getHotspotsList,
  getMakersList,
  getCitiesList,
  getMetricsHotspotsData,
  getOraclePricesList,
  getHeliumStats,
  getMakerDetails,
  getMakerList,
  postAddDevice,
};
