import React, { createContext, useContext } from 'react';
import type { Mixpanel } from 'mixpanel-browser';
const context = createContext<Mixpanel | null>(null);
export default context;

export const Consumer: React.Consumer<any> = context.Consumer;
export const Provider: React.Provider<any> = context.Provider;

export const useMixpanel = () => useContext(context);

export const withMixpanel = (Component: any) => (props: JSX.IntrinsicAttributes) => (
  <Consumer>
    {(mixpanel) => (
      <Component mixpanel={mixpanel} {...props} />
    )}
  </Consumer>
);
