import md5 from 'blueimp-md5'
import compress from './compress'
import adjectives from './adjectives'
import colors from './colors'
import occupations from './occupations'

export function toStyled(words: string[], style: string) {
  switch (style) {
    case 'titlecase':
      return words.map((w) => w.replace(/^\w/, (c) => c.toUpperCase()))
    case 'lowercase':
      return words.map((w) => w.toLowerCase())
    case 'uppercase':
      return words.map((w) => w.toUpperCase())
    default:
      throw new Error('Unknown style')
  }
}

export function format(words: string[], style: string, separator: string) {
  return toStyled(words, style).join(separator);
}

export function walletName (
  input: string,
  { style = 'titlecase', separator = ' ' } = {},
) {
  const hexdigest = md5(input)
  const pairs = hexdigest.match(/(..?)/g)
  const bytes = pairs ? pairs.map((x) => parseInt(x, 16)) : []
  const compressed = compress(bytes, 3)

  const adjective = adjectives[compressed[0]]
  const color = colors[compressed[1]]
  const occupation = occupations[compressed[2]]

  return format([adjective, color, occupation], style, separator)
}