/*
 * @Date: 2022-06-25 04:43:44
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-11-02 18:57:19
 * @FilePath: /kittens/src/assets/helper/thing.helper.ts
 * @Description: 
 */

import { localDateDiff } from "@/assets/utils/date.helper"
import { DevicesType, TreeItemType } from "@/interface/enum"
import { isBobcatGateway, isMobileGateway } from "../utils/base"
import StorageType from "@/type/storage.type"
// import store from "@/store"

export function SetThingsGroupDataFun(dataProps: TREE.TreeItem, thingId: string, 
  typeStatus: TreeItemType.Sensor | TreeItemType.Groups, keyValue: "parent_id" | "group_id"): { payload: true, data: any } | { payload: false, message: string } {
  // console.log('SetThingsGroupDataFun', {
  //   dataProps, thingId, typeStatus, keyValue
  // })
  if (thingId && dataProps.children) {
    const data = {
      [keyValue]: dataProps.id || "",
      next_id: "",
      prev_id: ""
    }
    let idFlag = true
    if (dataProps.children.length) {
      const getGroupsThingList = dataProps.children.filter((item) => item.typeStatus === typeStatus)
      if (getGroupsThingList.length) {
        idFlag = getGroupsThingList.every(item => {
          if (item.id === thingId) return false
          return true
        })
        if (idFlag) {
          if (dataProps.id) {
            if (getGroupsThingList.length) {
              data.prev_id = getGroupsThingList[getGroupsThingList.length - 1].id
            } else {
              data.prev_id = "0"
            }
          } else {
            data.prev_id = dataProps.prev_id || "0"
            data.next_id = dataProps.next_id || ""
          }
        } else {
          return { payload: false, message: `请勿重复添加相同的 ${typeStatus}` }
        }
      } else {
        data.prev_id = "0"
      }
    } else {
      data.prev_id = "0"
    }
    return { payload: true, data }
  }
  return { payload: false, message: `请选中 ${typeStatus}` }
}

export function setTemplateFormat(data: THINGS.GetThingListItem[], setter?: (item: THINGS.GetThingListItem) => object) {
  const list: THINGS.ThingTemplateFormatList = data.map(item => {
    const channels: any[] = setChannels(item.type.channels)
    const { defaultList, templateList } = getPayloadReturn(item.latest?.payload || null)
    const getChannles = getPayloadChannles(channels)
    const setterObj = typeof setter === "function" ? setter(item) : {}
    const templateFormatItem: THINGS.thingItem = {
      source: item,
      templateFormat: {
        id: item.id,
        payload: setAliasLable(templateListFun(templateList, channels), item.settings),
        channels: getChannles.defaultList,
        payload_channels: getChannles.templateList,
        name: item.name,
        alias: item.alias || undefined,
        type: item.type?.type,
        status: attributesFun(item.type.attributes, item.latest?.updated_at || ""),
        update_date: item.latest?.updated_at || "",
        defaultStatus: batteryAndSignalFilledFun(defaultList),
        latest: {
          channels: latestChannel(item.latest?.payload || [])
        }
      },
      ...setterObj
    }
    // console.log(`setTemplateFormat => ${item.name}`, {
    //   templateList,
    //   channels,
    //   templateFormatItem
    // }, "\n", "---------------------- ↑")
    return templateFormatItem
  })
  return list
}

export const latestChannel = (channels: THINGS.payloadItem[]): THINGS.templateFormatPayloadItem[] => {
  return channels.map(item => {
    return {
      label: item.t,
      key: item.c,
      value: item.v
    }
  })
}

export const batteryAndSignalFilledFun = (payload: THINGS.payloadItem[]) => {
  const batt = payload.find(item => item.c === 1) || { v: 0 }
  const rssi = payload.find(item => item.c === 2) || { v: 0 }
  return [
    { label: "Rssi", value: rssi.v, key: 2 },
    { label: "Batt", value: batt.v, key: 1 }
  ]
}

export const setAliasLable = (payload: THINGS.templateFormatPayloadItem[], settings: {[T in string]: string} | null) => {
  const getSetting = changeSetting(settings)
  return payload.map(item => {
    if (getSetting !== null) {
      if (Reflect.has(getSetting, item.key)) {
        item.label = getSetting[item.key as never]
      }
    }
    return item
  })
}

export const changeSetting = (settings: {[T in string]: string} | null) => {
  if (settings !== null) {
    let newSettings = {}
    for (const i in settings) {
      const IsI: string = i.split("channel_name")[1]
      Reflect.set(newSettings, IsI, settings[i])
    }
    return newSettings
  }
  return null
}

export const templateListFun = (templateList: THINGS.payloadItem[], channels: any[]) => {
  const getChannelMap = localStorage.getItem(StorageType.CHANNEL_TYPE_FILE)
  const channelTypeMap = getChannelMap && JSON.parse(getChannelMap)
  return templateList.map(item => {
    const channelItem = channels.find((channelItem: any) => channelItem.id === item.c)
    const value_V = `${Math.floor(Number(item.v) * 100) / 100} `
    const payloadItem: THINGS.templateFormatPayloadItem = {
      // label: item.t.split("_").join(" ").toLocaleUpperCase(),
      label: channelItem?.name || "",
      value: value_V,
      key: item.c
    }
    if (channelItem) {
      const localChannelItem = channelTypeMap.ChannelTypes[channelItem.type]
      if (localChannelItem) {
        switch (localChannelItem.type) {
          case "digital":
            if (Reflect.has(channelItem, "enum") && Reflect.ownKeys(channelItem.enum).length) {
              payloadItem.value = channelItem.enum[item.v]
            } else if (Reflect.has(localChannelItem, "enum") && Reflect.ownKeys(localChannelItem.enum).length) {
              payloadItem.value = localChannelItem.enum[item.v]
            }
          break
          case "lat":
            payloadItem.value = item.v
          break
          case "lng":
            payloadItem.value = item.v
          break
          default: 
            if (Reflect.has(item, "u") && item.u) {
              if (Reflect.has(localChannelItem, "option_units")) {
                localChannelItem.option_units.forEach((optionItem: any) => {
                  if (optionItem.key === item.u) {
                    payloadItem.value = `${value_V}${optionItem.unit}`
                    payloadItem["alias"] = item.u
                  }
                })
              } else {
                if (Reflect.has(localChannelItem, "default_unit")) {
                  payloadItem.value = `${value_V}${localChannelItem?.default_unit?.unit}`
                }
              }
            } else {
              if (Reflect.has(localChannelItem, "default_unit")) {
                payloadItem.value = `${value_V}${localChannelItem?.default_unit?.unit}`
              }
            }
          break
        }
      }
    }
    return payloadItem
  })
}

export const attributesFun = (attributesString: string, updated_at: string): THINGS.latestTypeName => {
  try {
    const attributes: { max_transmission_interval: number } = JSON.parse(attributesString)
    const updateDate = localDateDiff(updated_at, "s")
    if (updateDate > attributes.max_transmission_interval) {
      return "Offline"
    }
    return "Online"
  } catch (error) {
    return "Offline"
  }
}

const latestTypeFun = (latest: THINGS.thingLatest | null, channels: any[]): THINGS.latestTypeName => {
  if (latest !== null && latest.payload !== null && channels.length) {
    return "Online"
  } else if (latest !== null && latest.payload !== null) {
    return "Default"
  } else {
    return "Offline"
  }
}

export const setChannels = (channels: string) => {
  try {
    return JSON.parse(channels)
  } catch (error) {
    return []
  }
}

export const getPayloadReturn = (payload: THINGS.payloadItem[] | null) => {
  const defaultList: THINGS.payloadItem[] = []
  const templateList: THINGS.payloadItem[] = []
  if (payload === null) {
    return {
      defaultList, 
      templateList
    }
  }
  payload.forEach(item => {
    if (item.c <= 10) {
      defaultList.push(item)
    } else {
      templateList.push(item)
    }
  })
  return {
    defaultList,
    templateList
  }
}

export const getPayloadChannles = (channels: any[]) => {
  const defaultList: THINGS.payloadItem[] = []
  const templateList: THINGS.payloadItem[] = []
  if (channels.length) {
    channels.forEach(item => {
      if (item.id <= 10) {
        defaultList.push(item)
      } else {
        templateList.push(item)
      }
    })
  }
  return {
    defaultList,
    templateList
  }
}

export const setThingListSort = (thingList: THINGS.ThingTemplateFormatList) => {
  // 暂时注释排序
  // thingList.sort((a, b) => {
  //   return new Date(b.templateFormat.update_date).getTime() - new Date(a.templateFormat.update_date).getTime()
  // })
  return thingList
}

export const filterThingList = (thingList: THINGS.ThingTemplateFormatList, active: string) => {
  const filterList: THINGS.thingItem[] = []
  thingList.forEach(item => {
    if (active === "All") {
      filterList.push(item)
    } else if (active === item.templateFormat.status) {
      filterList.push(item)
    }
  })
  return filterList
}

export function isDeviceStatus(item: THINGS.GetThingListItem) {
  switch (item.properties?.device_type) {
    case DevicesType.Gateway:
      return isGatewayStatus(item)
    default: return DevicesType.Sensor
  }
}

export function isGatewayStatus(item: THINGS.GetThingListItem) {
  const isGateway300Or500 = isMobileGateway(item.gateway)
  if (isGateway300Or500) {
    return DevicesType.Hotspot500
  }
  if (!isBobcatGateway(item.gateway as any)) {
    return DevicesType.HotspotOther
  }
  return DevicesType.Hotspot300
}

export const getTemplateFormatList = (list: THINGS.GetThingListItem[], parentId: string) => {
  const TemplateFormatList = setTemplateFormat(list, (item) => {
    const DeviceStatus = isDeviceStatus(item)
    let obj = {
      parent_id: parentId,
      children: [],
      typeStatus: TreeItemType.Sensor,
      prev_id: item.prev_id,
      id: item.id,
      DeviceStatus: DeviceStatus,
      owner: item.owner
    }
    if (DeviceStatus !== DevicesType.Sensor) {
      Reflect.set(obj, DeviceStatus, item.gateway)
    }
    return obj
  }) as THINGS.treeThingItem[]

  // setp mobile radios, it will cause 429-too many requests if there are too many gateways
  // for (let i = 0; i < TemplateFormatList.length; i++ ) {
  //   if (isMobileGateway(TemplateFormatList[i]?.gateway)) {
  //     await getGatewayMobileRadios(TemplateFormatList[i]?.gateway.pubkey).then(mobile => {
  //       Reflect.set(TemplateFormatList[i]?.gateway, "latest_status", mobile)
  //     })
  //   }
  // }
  return TemplateFormatList
}

export const parseIdAndDeviceId = (idAndDevice: string | undefined) => {
  if (idAndDevice) {
    const [id, device] = idAndDevice.split("&")
    return { id, device: device as DevicesType.Hotspot300 | DevicesType.Hotspot500 }
  } else {
    return {
      id: "",
      device: undefined
    }
  }
}

export const averageLatLng = (List: THINGS.ThingTemplateFormatList): mapboxgl.LngLatLike => {
  if (List.length < 1) return [0, 0]
  const latLngArr: { lat: string; lng: string }[] = []

  List.forEach(item => {
    item.templateFormat.latest.channels?.forEach(items => {
      if (items.label === "coord") {
        if (typeof items.value === "string") {
          const [lat, lng] = items.value.split(",")
          latLngArr.push({lat, lng})
        }
      }
    })
  })

  const coordinates = latLngArr.map(({ lat, lng }) => [parseFloat(lat), parseFloat(lng)]);
  
  // 将所有经纬度求和，然后计算平均值
  const averageLatLng = coordinates.reduce(([latSum, lngSum], [lat, lng]) => [latSum + lat, lngSum + lng], [0, 0])
  .map(sum => sum / coordinates.length);

  // 将计算的平均值放回对象中
  const centerPoint = { lat: averageLatLng[0].toFixed(4), lng: averageLatLng[1].toFixed(4) };
  return [Number(centerPoint.lat), Number(centerPoint.lng)]
}
