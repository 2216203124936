import mixpanel from 'mixpanel-browser';
import { Provider } from './mixpanel';

const defaults = {
  debug: true,
  track_pageview: false, // Rarely makes sense to track page views in React apps
};

const MixpanelProvider = ({ children, config, name }: any) => {

  config = Object.assign({}, defaults, config);

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!, config, name);

  return (
    <Provider value={mixpanel}>
      {children}
    </Provider>
  );

};

export default MixpanelProvider;
