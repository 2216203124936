/*
 * @Date: 2022-04-25 13:55:02
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-20 15:30:33
 * @FilePath: /kittens/src/store/slice/thing.type.slice.ts
 * @Description: 
 */

import cloneDeep from "lodash/cloneDeep";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { onGetThingTypes } from '@/services/thing.type';

interface initState {
  thingTypes: any;
  thingMakers: any;
}

const initialState: initState = {
  thingTypes: null,
  thingMakers: null
}

const ThingTypesSlice = createSlice({
  name: "ThingTypesSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(FETCH_THING_TYPE_LIST.fulfilled, (state, action) => {
      const makers: Set<string> = new Set();
      action.payload.forEach((item: any) => {
        makers.add(item.maker);
      });

      state.thingTypes = action.payload;
      state.thingMakers = Array.from(makers).sort();
    })
  }
})

const FETCH_THING_TYPE_LIST = createAsyncThunk(
  "FETCH_THING_TYPE_LIST", async () => {
    const res = await onGetThingTypes({current: 1, pageSize: 100, result: "all" })
    if (res.success && res.payload.list) {
      if (res.payload.list.length) {  
        return res.payload.list.filter((item: any) => item.type !=='hotspot')
      }
    }
    return []
  }
)

export { FETCH_THING_TYPE_LIST }
export default ThingTypesSlice