/*
 * @Date: 2022-04-24 22:27:51
 * @LastEditors: Save
 * @LastEditTime: 2022-08-04 02:52:34
 * @FilePath: /src/store/slice/home.slice.ts
 * @Description: 
 */

import cloneDeep from "lodash/cloneDeep";
import { getHeliumStats, getMetricsHotspotsData, getOraclePricesList } from "@/services/helium.fetch";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export type OverviewModelState = {
  heliumStateBody: any;
  oraclePricesList: any;
  metricsHotspots: any;
  loading: boolean;
};

const InitState: OverviewModelState = {
  heliumStateBody: false,
  oraclePricesList: [],
  metricsHotspots: {
    hotspots_count: [],
    online_pct: [],
    // owners_count: [],
    cities_count: [],
    countries_count: [],
  },
  loading: false,
}

const HomeSlice = createSlice({
  name: "homeSlice",
  initialState: cloneDeep(InitState),
  reducers: {
    clearInitState: () => {
      return cloneDeep(InitState)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(FETCH_HOME_ALL.pending, (state, action) => {
      state.loading = true
    })
    .addCase(FETCH_HOME_ALL.fulfilled,  (state, action) => {
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    })
    .addCase(FETCH_HOME_ALL.rejected, (state) => {
      return {
        ...state,
        loading: false
      }
    })
  }
})

const FETCH_HOME_ALL = createAsyncThunk(
  "FETCH_HOME_ALL", async () => {
    const resList = await Promise.all([getHeliumStats(), getOraclePricesList(), getMetricsHotspotsData()])
    //console.log('FETCH_HOME_ALL', resList)
    return {
      heliumStateBody: resList[0],
      oraclePricesList: resList[1],
      metricsHotspots: resList[2],
    }
  }
)

export { FETCH_HOME_ALL }
export default HomeSlice
