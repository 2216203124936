/*
 * @Date: 2022-04-21 21:31:56
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-31 14:25:59
 * @FilePath: /kittens/src/index.tsx
 * @Description: 
 */
// import VConsole from 'vconsole';
import 'antd-mobile/es/global'
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { HashRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import InitStateSlice from './store/slice/init.state.slice';
import MixpanelProvider from './assets/utils/react-mixpanel-browser';
// new VConsole();
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <MixpanelProvider>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
      {/* <BrowserRouter>
        <App />
      </BrowserRouter> */}
    </Provider>
  </MixpanelProvider>
)

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// if (process.env.NODE_ENV === "development") {
//   serviceWorkerRegistration.unregister()
// } else {

// }
serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    console.log('onSuccess 有新的内容成功，请点击确定进行更新。=>', registration)
    registration.update().then(updateItem => {
      console.log('updateItem', updateItem)
      registration.unregister().then(res => {
        console.log('unregister =>', res)
        // window.location.reload();
        // store.dispatch(InitStateSlice.actions.setpwaUpdate("yes"))
      })
    })
  },
  onUpdate: (registration) => {
    console.log('onUpdate 有新的内容需要更新，请点击确定进行更新。=>', registration)
    registration.update().then(updateItem => {
      console.log('updateItem', updateItem)
      registration.unregister().then(res => {
        console.log('unregister =>', res)
        // window.location.reload();
        // store.dispatch(InitStateSlice.actions.setpwaUpdate("yes"))
      })
    })
  }
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
