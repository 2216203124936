/*
 * @Author: Xiaowei Zhang 2244661996@qq.com
 * @Date: 2022-09-28 23:35:28
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-31 14:50:05
 * @FilePath: /kittens/src/pages/pages.router.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// navigate

import { lazy, Suspense } from 'react'
import { Route, RouteObject } from 'react-router-dom'
import { Domain, thingDetailsRouteEnum } from '@/interface/enum'
import { Role } from '@/services/login'
import { envConfig } from '@/api/api.url'
import RouteLoading from "@/component/loading"
import cloneDeep from "lodash/cloneDeep";

// no account
const NotAccountWelcome = lazy(() => import('@/pages/no-account'))
const Login = lazy(() => import('@/pages/no-account/login'))
const Register = lazy(() => import('@/pages/no-account/register'))
const ForgotStack = lazy(() => import('@/pages/no-account/forgot'))
const PlanStask = lazy(() => import("@/pages/no-account/plans"))

// account
const WelcomeRoutes = lazy(() => import('@/pages/account'))
const HomeStack = lazy(() => import('@/pages/account/home'))
const GatewayStack = lazy(() => import('@/pages/account/gateway'))
const UserStack = lazy(() => import('@/pages/account/user'))
const RuleStack = lazy(() => import('@/pages/account/rule'))
const HostsStack = lazy(() => import('@/pages/account/hosts'))
const InfoStack = lazy(() => import('@/pages/account/setting'))
const UserShareStask = lazy(() => import("@/pages/account/thing-share/user.share"))
// const QrStask = lazy(() => import('@/pages/account/qr'))
const SortableStask = lazy(() => import('@/pages/account/sortable'))
const SortableDeviceMap = lazy(() => import("@/pages/account/sortable/device.map"))
const ThingStask = lazy(() => import("@/pages/account/thing"))
const ThingDetailsStask = lazy(() => import("@/pages/account/thing-details"))
// const ThingDetailsDeviceSettings = lazy(() => import("@/pages/account/thing-details/tabs/edit"))
// const ThingDetailsHistory = lazy(() => import("@/pages/account/thing-details/tabs/history"))
// const ThingDetailsNotifications = lazy(() => import("@/pages/account/thing-details/tabs/notifications"))
// const ThingDetailsChat = lazy(() => import("@/pages/account/thing-details/tabs/chart"))
const ThingAlertStask = lazy(() => import("@/pages/account/thing-alert"))
const ThingCreateAlertStask = lazy(() => import("@/pages/account/thing-alert/create-alert"))
const ThingEditAlertStack = lazy(() => import("@/pages/account/thing-alert/edit-alert"))
const ThingTypesStask = lazy(() => import("@/pages/account/thing-type"))
const ThingTypeDetailsStask = lazy(() => import("@/pages/account/thing-type/details"))
const ThingTypeCreateStask = lazy(() => import("@/pages/account/thing-type/create"))
const ThingTypeEditStask = lazy(() => import("@/pages/account/thing-type/edit"))
const ThingAddDeviceStask = lazy(() => import("@/pages/account/sortable/add-device"))

const ThingDetailsDeviceSettings = lazy(() => import("@/pages/account/thing-details/tabs/edit"))
const ThingDetailsHistory = lazy(() => import("@/pages/account/thing-details/tabs/history"))
const ThingDetailsNotifications = lazy(() => import("@/pages/account/thing-details/tabs/notifications"))
const ThingDetailsChat = lazy(() => import("@/pages/account/thing-details/tabs/chart"))
const ThingDetailsAlert = lazy(() => import("@/pages/account/thing-details/tabs/alert"))
const ThingDetailsAlertCreate = lazy(() => import("@/pages/account/thing-details/tabs/alert/create.alert"))
// const ThingDetailsAlertDetailsStask = lazy(() => import("@/pages/account/thing-details/tabs/alert/alert.details"))
const ThingDetailsAlertContentStask = lazy(() => import("@/pages/account/thing-details/tabs/alert/alert.content"))
// const ThingDetailsAlertEditStask = lazy(() => import("@/pages/account/thing-details/tabs/alert/edit.alert"))
const ThingDetailsMap = lazy(() => import("@/pages/account/thing-details/tabs/map"))
const ThingShareStack = lazy(() => import("@/pages/account/thing-share"))
const GatewayDetailsStask = lazy(() => import("@/pages/account/thing-details/tabs/gateway-detail"))
const ThingNoteStack = lazy(() => import("@/pages/account/thing-details/tabs/note"))
const ThingAddShareUserStask = lazy(() => import("@/pages/account/thing-share/add.share.user"))

const ArticleStask = lazy(() => import("@/pages/account/article"))
const ArticleCreateStask = lazy(() => import("@/pages/account/article/create"))
const ArticleSourceDetailStask = lazy(() => import("@/pages/account/article/source-article/detail"))
const ArticlePublishedDetailStask = lazy(() => import("@/pages/account/article/published-article/detail"))

// --------------Ebike stask -------------

// ---------------------------------------


const UtilsStack = lazy(() => import("@/pages/account/utils"))
const PriceStack = lazy(() => import("@/pages/account/price"))

const NoFoundPage = lazy(() => import("@/pages/exception/404"))


interface RouteObjectProps extends RouteObject {
  permission: USER.Role[]
  doMain: BASE.DomainName[]
  children?: RouteObjectProps[]
}

const thingDetailsRoute: RouteObjectProps[] = [
  {
    path: thingDetailsRouteEnum.alert,
    permission: [Role.Root, Role.Support, Role.Tenant],
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    children: [
      {
        path: "list",
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
        children: [
          {
            index: true,
            element: <ThingDetailsAlert />,
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
            permission: [Role.Root, Role.Support, Role.Tenant],
          },
          {
            path: ":idAndType",
            element: <ThingDetailsAlertContentStask />,
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
            permission: [Role.Root, Role.Support, Role.Tenant],
          },
          // {
          //   path: "details/:alertId",
          //   element: <ThingDetailsAlertDetailsStask />,
          //   doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          //   permission: [Role.Root, Role.Support, Role.Tenant],
          // },
          // {
          //   path: "edit/:alertId",
          //   element: <ThingDetailsAlertEditStask />,
          //   doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          //   permission: [Role.Root, Role.Support, Role.Tenant],
          // }
        ]
      },
      {
        path: "create",
        element: <ThingDetailsAlertCreate />,
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
        permission: [Role.Root, Role.Support, Role.Tenant],
      }
    ]
  },
  {
    path: `${thingDetailsRouteEnum.alert}/create`,
    element: <ThingDetailsAlertCreate />,
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    permission: [Role.Root, Role.Support, Role.Tenant],
  },
  {
    path: thingDetailsRouteEnum.history,
    element: <ThingDetailsHistory />,
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    permission: [Role.Root, Role.Support, Role.Tenant],
  },
  {
    path: thingDetailsRouteEnum.chart,
    element: <ThingDetailsChat />,
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    permission: [Role.Root, Role.Support, Role.Tenant],
  },
  {
    path: thingDetailsRouteEnum.notifications,
    element: <ThingDetailsNotifications />,
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    permission: [Role.Root, Role.Support, Role.Tenant],
  },
  {
    path: thingDetailsRouteEnum.alert,
    element: <ThingDetailsAlert />,
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    permission: [Role.Root, Role.Support, Role.Tenant],
  },
  {
    path: thingDetailsRouteEnum.setting,
    element: <ThingDetailsDeviceSettings />,
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    permission: [Role.Root, Role.Support, Role.Tenant],
  },
  {
    path: thingDetailsRouteEnum.map,
    element: <ThingDetailsMap />,
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    permission: [Role.Root, Role.Support, Role.Tenant],
  },
  {
    path: thingDetailsRouteEnum.detail,
    element: <GatewayDetailsStask />,
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    permission: [Role.Root, Role.Support, Role.Tenant],
  },
  {
    path: thingDetailsRouteEnum.note,
    element: <ThingNoteStack />,
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    permission: [Role.Root, Role.Support, Role.Tenant],
  }
]

export const EXCEPTION_ROUTERS: RouteObject[] = [
  {
    path: "*",
    element: <NoFoundPage />
  }
]

export const LOADING_ROUTERS: RouteObject[] = [
  {
    path: "*",
    element: <RouteLoading />
  }
]

export const NO_ACCOUNT_ROUTERS: RouteObjectProps[] = [
  {
    path: "user", 
    element: <NotAccountWelcome />,
    permission: [Role.Root, Role.Support, Role.Tenant],
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    children: [
      {
        path: "login",
        element: <Login />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
      },
      {
        path: "register",
        element: <Register />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.EBIKE]
      },
      {
        path: "forgot",
        element: <ForgotStack />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.EBIKE]
      }
    ]
  },
  {
    path: "plans", 
    element: <NotAccountWelcome />,
    permission: [Role.Root, Role.Support, Role.Tenant],
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
    children: [
      {
        path: ":Plan/claim",
        element: <PlanStask />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      }
    ]
  }
]

export const ACCOUNT_ROUTERS: RouteObjectProps[] = [
  {
    path: envConfig.REACT_APP_ROOT_PATH, 
    element: <WelcomeRoutes />,
    permission: [Role.Root, Role.Support, Role.Tenant],
    doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
    children: [
      {
        path: "overview",
        element: <HomeStack />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      },
      {
        path: "gateway", element: <GatewayStack />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      },
      {
        path: "user", element: <UserStack />,
        permission: [Role.Root],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      },
      {
        path: "rule", element: <RuleStack />,
        permission: [Role.Root],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      },
      {
        path: "hosts", element: <HostsStack />,
        permission: [Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      },
      {
        path: "settings", element: <InfoStack />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      },
      {
        path: "qr", element: <ThingAddDeviceStask />,
        permission: [Role.Root],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
      },
      {
        path: "sortable",
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
        children: [
          {
            index: true,
            element: <SortableStask />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
          },
          {
            path: "?thing_id=:id",
            element: <SortableStask />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
          },
          {
            path: ":id", // details/:id/:device
            element: <ThingDetailsStask />,
            children: thingDetailsRoute,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
          },
          {
            path: "alert",
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
            children: [
              {
                index: true,
                element: <ThingAlertStask />,
                permission: [Role.Root, Role.Support, Role.Tenant],
                doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
              },
              {
                path: "create",
                element: <ThingCreateAlertStask />,
                permission: [Role.Root, Role.Support, Role.Tenant],
                doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
              },
              {
                path: "edit",
                element: <ThingEditAlertStack />,
                permission: [Role.Root, Role.Support, Role.Tenant],
                doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
              }
            ]
          },
          {
            path: "create/devices",
            element: <ThingAddDeviceStask />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          },
          {
            path: "share/:id",
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
            children: [
              {
                index: true,
                element: <ThingShareStack />,
                permission: [Role.Root, Role.Support, Role.Tenant],
                doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT,  Domain.EBIKE],
              },
              {
                path: "add",
                element: <ThingAddShareUserStask />,
                permission: [Role.Root, Role.Support, Role.Tenant],
                doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT,  Domain.EBIKE],
              }
            ]
          },
          // {
          //   path: "history", element: <ThingDetailsHistory />
          // },
          // {
          //   path: "notifications", element: <ThingDetailsNotifications />
          // },
          // {
          //   path: "chart", element: <ThingDetailsChat />
          // },
          // {
          //   path: "edit", element: <ThingDetailsDeviceSettings />
          // }
          {
            path: "sensor/:parentId/:lat/:lng",
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
            children: [
              {
                index: true,
                element: <SortableDeviceMap />,
                permission: [Role.Root, Role.Support, Role.Tenant],
                doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
              },
              {
                path: ":idAndDeviceId",
                element: <ThingDetailsStask />,
                children: thingDetailsRoute,
                permission: [Role.Root, Role.Support, Role.Tenant],
                doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
              }
            ]
          }
        ]
      },
      {
        path: "thing", 
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
        children: [
          {
            index: true,
            element: <ThingStask />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
          },
          {
            path: ":id/edit",
            element: <ThingEditAlertStack />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
          },
          {
            path: ":id",
            element: <ThingDetailsStask />,
            children: thingDetailsRoute,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
          },
        ]
      },
      {
        path: "share",
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
        children: [
          {
            index: true,
            element: <UserShareStask />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT, Domain.EBIKE],
          }
        ]
      },
      {
        path: "thingType",
        permission: [Role.Root],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
        children: [
          {
            index: true,
            element: <ThingTypesStask />,
            permission: [Role.Root],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          },
          {
            path: "details/:id",
            element: <ThingTypeDetailsStask />,
            permission: [Role.Root],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          },
          {
            path: "create",
            element: <ThingTypeCreateStask />,
            permission: [Role.Root],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          },
          {
            path: "edit/:id",
            element: <ThingTypeEditStask />,
            permission: [Role.Root],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          }
        ]
      },
      // {
      //   path: "alert",
      //   permission: [Role.Root, Role.Support, Role.Tenant],
      //   children: [
      //     {
      //       index: true,
      //       element: <ThingAlertStask />,
      //       permission: [Role.Root, Role.Support, Role.Tenant],
      //     },
      //     {
      //       path: "create",
      //       element: <ThingCreateAlertStask />,
      //       permission: [Role.Root, Role.Support, Role.Tenant],
      //     }
      //   ]
      // },
      {
        path: "utils",
        element: <UtilsStack />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      },
      {
        path: "price",
        element: <PriceStack />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      },
      {
        path: "article",
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
        children: [
          {
            index: true,
            element: <ArticleStask />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          },
          {
            path: "create",
            element: <ArticleCreateStask />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          },
          {
            path: "source/:id",
            element: <ArticleSourceDetailStask />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          },
          {
            path: "published/:id",
            element: <ArticlePublishedDetailStask />,
            permission: [Role.Root, Role.Support, Role.Tenant],
            doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
          }
        ]
      },
      /*
      {
        path: "subscription",
        element: <SubscriptionStask />,
        permission: [Role.Root, Role.Support, Role.Tenant],
        doMain: [Domain.CLOUD, Domain.STAGING, Domain.SUPPORT],
      }
      */
    ]
  }
]

export function addSuspense(routes: RouteObject[]) {
  for (const item of routes) {
    item.element = (
      <Suspense fallback={<RouteLoading />}>
        {item.element}
      </Suspense>
    )
    if (item.children && item.children.length) {
      item.children = addSuspense(item.children)
    }
  }
  return routes
}

export function setRouters(routes: RouteObject[]) {
  let route: React.ReactElement[] = []
  routes.forEach((item, key) => {
    if (item.children && item.children.length) {
      route.push(
        <Route 
          key={key}
          {...item}
          element={item.element && (
            <Suspense fallback={<RouteLoading />}>
              {item.element}
            </Suspense>
          )}
          children={setRouters(item.children)}
        />
      )
    } else {
      route.push(
        <Route 
          key={key}
          {...item}
          children={null}
          element={
            <Suspense fallback={<RouteLoading />}>
              {item.element}
            </Suspense>
          }
        />
      )
    }
  })
  return route
}

interface permissionRouterProps {
  permission: USER.Role[]
  doMain: BASE.DomainName[]
  children?: permissionRouterProps[]
}

export function permissionRouter<T extends permissionRouterProps = permissionRouterProps>(routers: T[], rote: USER.Role) {
  const newRouter: T[] = []
  const getCloneDeepRouters = cloneDeep(routers)
  getCloneDeepRouters.forEach(item => {
    if (item.doMain.includes(envConfig.REACT_APP_DOMAIN)) {
      if (item.permission.includes(rote)) {
        if (item.children && item.children.length) {
          item.children = permissionRouter(item.children, rote)
        }
        newRouter.push(item)
      }
    }
  })
  return newRouter
}
