/*
 * @Date: 2022-04-24 22:35:28
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-11-01 17:05:44
 * @FilePath: /kittens/src/api/api.helium.ts
 * @Description: 
 */

const HOST = '/v1/';
const HOST_API = '/api/';

const BASE_URL_HELIUM = 'https://explorer-api.helium.com';

const API_URL_HELIUM = {
  status: `${HOST}stats`,
  hotspots: `${HOST}hotspots`,
  makers: `${HOST_API}makers`,
  cities: `${HOST}cities`,
  metricsHotspots: `${HOST_API}metrics/hotspots`,
  hotspotcells: `${HOST_API}cell/hotspots/{pubkey}/cells`,
  // hotspotsLatestSpeedtest: `${HOST_API}cell/hotspots/{pubkey}/latest-speedtest`
  hotspotsAvgSpeedtest: `${HOST_API}cell/hotspots/{pubkey}/avg-speedtest`,
  hotspotRewards: `${HOST_API}cell/hotspots/{pubkey}/rewards`
};

export { BASE_URL_HELIUM, API_URL_HELIUM };