/*
 * @Date: 2022-04-25 13:55:02
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-26 15:19:17
 * @FilePath: /kittens/src/store/slice/earnings.slice.ts
 * @Description: 
 */

import { getEarnings } from "@/services/earnings";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

interface initState {
  earnings: any;
}

const initialState: initState = {
  earnings: null
}

const EarningsSlice = createSlice({
  name: "EarningsSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(FETCH_EARNINGS.fulfilled, (state, action) => {
      state.earnings = action.payload;
    })
    .addCase(FETCH_EARNINGS.rejected, (state) => {
      state.earnings = {}
    })
  }
})

const FETCH_EARNINGS = createAsyncThunk(
  "FETCH_EARNINGS", async () => {
    const res = await getEarnings()
    return res
  }
)

export { FETCH_EARNINGS }
export default EarningsSlice