/*
 * @Date: 2022-04-21 23:29:24
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-11 13:15:15
 * @FilePath: /kittens/src/store/slice/init.state.slice.ts
 * @Description: 
 */
import cloneDeep from "lodash/cloneDeep";
import { geCurrenttUser } from '@/services/login';
import { onGetThingChannelType } from '@/services/thing.type';
import StorageType from '@/type/storage.type';
import { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { envConfig } from "@/api/api.url";
import { ENV_CONFIG } from "@/interface/envConfig";

interface initState {
  settings: ENV_CONFIG.ProLayoutSettings,
  currentUser: USER.CurrentUserReqBody | null,
  pathname: string
  pwaUpdate: string
  channelTypeMap: {
    [T in string]: any
  },
  channelType: {
    [T in string]: string
  }
}
envConfig
const initialState: initState = {
  settings: envConfig.ProLayoutSettings,
  currentUser: null,
  pathname: "/",
  pwaUpdate: "",
  channelTypeMap: {},
  channelType: {}
}

const InitStateSlice = createSlice({
  name: "InitStateSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload
    },
    setSettings: (state, action:  PayloadAction<any>) => {
      state.settings = action.payload
    },
    setPathname: (state, action: PayloadAction<string>) => {
      state.pathname = action.payload
    },
    setpwaUpdate: (state, action: PayloadAction<string>) => {
      state.pwaUpdate = action.payload
    },
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(FETCH_CURRENT_USER.fulfilled, (state, action) => {
      const { currentUser } = action.payload
      // const path = window.location.hash.split("#")[1]
      // let url = path
      // const pathFlag = path.includes("/welcome")
      // if (pathFlag) {
      //   url = path || "/welcome/overview"
      // } else {
      //   url = "/welcome/overview"
      // }
      state.currentUser = currentUser
      // return {
      //   ...state,
      //   currentUser,
      //   pathname: url
      // }
    })
    .addCase(FETCH_CURRENT_USER.rejected, (state) => {
      state.pathname = "/user/login"
      localStorage.removeItem(StorageType.TOKEN_DATA);
    })
    .addCase(FETCH_CHANNELS_TYPE_JSON_FILE.pending, (state) => {
      const getItemString = localStorage.getItem(StorageType.CHANNEL_TYPE_FILE)
      if (getItemString) {
        const getItem = JSON.parse(getItemString)
        state.channelType = getItem.typeObject
        state.channelTypeMap = getItem.ChannelTypes
      }
    })
    .addCase(FETCH_CHANNELS_TYPE_JSON_FILE.fulfilled, (state, action) => {
      const ChannelTypes = JSON.parse(action.payload.types)
      const typeObject: any = {}
      for (const i in ChannelTypes) typeObject[i] = i;
      localStorage.setItem(StorageType.CHANNEL_TYPE_FILE, JSON.stringify({typeObject, ChannelTypes}))
      state.channelType = typeObject
      state.channelTypeMap = ChannelTypes
    })
    .addCase(FETCH_CHANNELS_TYPE_JSON_FILE.rejected, (state) => {
      state.channelType = {}
      state.channelTypeMap = {}
      localStorage.setItem(StorageType.CHANNEL_TYPE_FILE, JSON.stringify({typeObject: {}, ChannelTypes: {}}))
    })
  }
})

export const FETCH_CURRENT_USER = createAsyncThunk(
  "FETCH_CURRENT_USER", async () => {
    
    const currentUser = await geCurrenttUser()
    // const sourceRoutes = routeFun()
    // const routes: MenuDataItem[] = []
    
    // if (currentUser?.role) {
    //   sourceRoutes.forEach(item => {
    //     if (item.permission.includes(currentUser.role)) {
    //       routes.push(item)
    //     }
    //   })
    // }

    // if (path === "/welcome/subscription") {
    //   const getSUBSCRIPTION_CLAIM = localStorage.getItem(storageType.SUBSCRIPTION_CLAIM)
    //   console.log('getSUBSCRIPTION_CLAIM', getSUBSCRIPTION_CLAIM)
    //   if (getSUBSCRIPTION_CLAIM) {
    //     const parseSUBSCRIPTION_CLAIM: SUBSCRIPTION.ClaimReq = JSON.parse(getSUBSCRIPTION_CLAIM)
    //     await onPostClaim(parseSUBSCRIPTION_CLAIM)
    //   }
    // }
    
    // const setting: any = {
    //   route: {
    //     path: '/welcome',
    //     routes
    //   }
    // }
    
    return {
      currentUser
      // setting
    }
  }
)

export const FETCH_CHANNELS_TYPE_JSON_FILE = createAsyncThunk(
  "FETCH_CHANNELS_TYPE_JSON_FILE", async () => {
    const res = await onGetThingChannelType()
    if (res.success) {
      return res.payload
    }
  }
)

export default InitStateSlice