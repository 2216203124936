/*
 * @Date: 2022-06-09 01:50:51
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-11-02 18:36:53
 * @FilePath: /kittens/src/store/slice/thing.slice.ts
 * @Description: 
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { onGetThing, onGetThingList } from "@/services/things";
import { requestDataFunction } from '@/assets/utils/base';
import { isDeviceStatus, isGatewayStatus, setTemplateFormat, setThingListSort } from "@/assets/helper/thing.helper"
import { mergeSocketAndThingListData } from "@/assets/helper/thing.type.helper";
import { onGetThingGroups } from "@/services/thing.group";
import { DevicesType, SharePermEnum } from "@/interface/enum";
import cloneDeep from "lodash/cloneDeep";
interface initState {
  ThingList: THINGS.GetThingListItem[]
  thingDetails: THINGS.thingItem | null
  thingDetailsUpdateCount: number
  thingDetailsLoading: boolean
  ThingListTotal: number
  ThingListLoading: boolean
  ThingListOps: {
    List: THINGS.ThingTemplateFormatList
    Loading: boolean
    Total: number
    params: BASE.ListQuery
    ListStauts: "0" | "1" | "2",
    sourceList: THINGS.ThingTemplateFormatList
  }
}

const initialState: initState = {
  ThingList: [],
  ThingListOps: {
    List: [],
    Loading: false,
    Total: 0,
    params: { current: 1, pageSize: 20 },
    ListStauts: "0",
    sourceList: []
  },
  ThingListTotal: 0,
  thingDetails: null,
  thingDetailsLoading: true,
  ThingListLoading: false,
  thingDetailsUpdateCount: 0
}

const ThingsSlice = createSlice({
  name: "ThingsSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    updateWsData: (state, action: PayloadAction<SCOKET.onMessageProps[]>) => {
      const { ThingListOps } = state
      const socketList = action.payload
      const StateList = mergeSocketAndThingListData(ThingListOps.List, socketList)
      state.ThingListOps.List = StateList
    },
    setThingDetailsUpdateCount: (state) => {
      state.thingDetailsUpdateCount = state.thingDetailsUpdateCount += 1
    },
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(FETCH_GET_THING_LIST.pending, (state, action) => {
      state.ThingListOps.Loading = true
      state.ThingListOps.params = action.meta.arg
      if (state.ThingListOps.params.current === 1) {
        state.ThingListOps.List = []
        state.ThingListOps.ListStauts = "0"
      }
    })
    .addCase(FETCH_GET_THING_LIST.fulfilled, (state, action) => {
      const { data, total } = action.payload
      state.ThingListOps.Loading = false
      state.ThingListOps.Total = total || 0
      if (data) {
        if (state.ThingListOps.List.length) {
          const newList = [
            ...state.ThingListOps.List, 
            ...setTemplateFormat(data, item => {
              const DeviceStatus = isDeviceStatus(item)
              let obj = { DeviceStatus }
              if (DeviceStatus !== DevicesType.Sensor) {
                Reflect.set(obj, DeviceStatus, item.gateway)
              }
              return obj
            })
          ]
          state.ThingListOps.List =  setThingListSort(newList)
        } else {
          state.ThingListOps.List = setThingListSort(setTemplateFormat(data, item => {
            const DeviceStatus = isDeviceStatus(item)
            let obj = { DeviceStatus }
            if (DeviceStatus !== DevicesType.Sensor) {
              Reflect.set(obj, DeviceStatus, item.gateway)
            }
            return obj
          }))
        }
      }
      if (state.ThingListOps.List.length) {
        state.ThingListOps.ListStauts = "1"
      } else {
        state.ThingListOps.ListStauts = "2"
      }
      state.ThingListOps.sourceList = state.ThingListOps.List
    })

    .addCase(FETCH_GET_THINGS.pending, (state) => {
      state.thingDetailsLoading = true
    })
    .addCase(FETCH_GET_THINGS.fulfilled, (state, action) => {
      const { data, share_perm } = action.payload
      if (data) {
        state.thingDetails = setTemplateFormat([data], (item) => {
          const DeviceStatus = isDeviceStatus(item)
          let obj = {
            share_perm,
            typeStatus: DeviceStatus
          }
          if (DeviceStatus !== DevicesType.Sensor) {
            Reflect.set(obj, DeviceStatus, item.gateway)
          }
          return obj
        })[0]
      }
      state.thingDetailsLoading = false
    })
    .addCase(FETCH_GET_THINGS.rejected, (state) => {
      state.thingDetailsLoading = true
      state.thingDetails = null
    })
  }
})

export const FETCH_GET_THING_LIST = createAsyncThunk(
  "FETCH_GET_THING_LIST", async (params: BASE.ListQuery) => {
    const setpOne = await onGetThingList(params)
    const setpTwo = await requestDataFunction<THINGS.GetThingListItem>(setpOne);
    // if (!data.data) return data
    // for (let i = 0; i < data.data?.length; i++) {
    //   if (data.data && isMobileGateway(data.data[i].gateway)) {
    //     await getGatewayMobileRadios(data.data[i].gateway.pubkey).then(mobile => {
    //       if (data.data) {
    //         Reflect.set(data.data[i].gateway, "latest_status", mobile)
    //       }
    //     })
    //   }
    // }
    return setpTwo
  }
)

export const FETCH_GET_THINGS = createAsyncThunk(
  "FETCH_GET_THINGS", async (id: string) => {
    const resObj: any = {
      data: null,
      share_perm: SharePermEnum.RO
    }
    try {
      const res = await onGetThing(id)
      if (res.success) {
        resObj.data = res.payload
        if (res.payload.group_id) {
          const stepTwo = await onGetThingGroups(res.payload.group_id)
          const share_perm = stepTwo.success && stepTwo.payload.share_perm || "root"
          resObj.share_perm = share_perm
        }
        // if (isMobileGateway(resObj.data.gateway)) {
        //   await getGatewayMobileRadios(resObj.data.gateway.pubkey).then(mobile => {
        //     Reflect.set(resObj.data.gateway, "latest_status", mobile)
        //   })
        // }
      }
    } finally {
      return resObj
    }
  }
)

export default ThingsSlice;
