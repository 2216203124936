/*
 * @Date: 2022-04-22 22:44:32
 * @LastEditors: Save
 * @LastEditTime: 2022-09-06 11:00:05
 * @FilePath: /src/services/login.ts
 * @Description: 
 */
import { ApiUrlLogin } from '@/api/api.url';
import { GlobalAxios } from '@/api/http';

export function postRegister(data: USER.PostRegister) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlLogin.Register },
    alertMsg: 'rigister'
  }).fetch<{ data: USER.ReqRegisterData }>('POST', data);
}

export function postForgot(data: USER.ForgotResData) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlLogin.Forgot },
    alertMsg: 'forgot'
  }).fetch<{ ok: boolean }>('POST', data);
}

export function postLogin(data: USER.LoginResData) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlLogin.Login },
    alertMsg: 'login'
  }).fetch<USER.LoginReqBody>('POST', data);
}

export function postLogout() {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlLogin.CurrentLogout },
    alertMsg: 'login'
  }).fetch<{ ok: boolean }>('POST');
}

export enum Role {
  // Normal = 'Normal',
  Tenant = 'Tenant',
  Support = 'Support',
  Root = 'Root',
}

export async function geCurrenttUser() {
  const res = await new GlobalAxios({
    axiosOptions: { url: ApiUrlLogin.CurrentUser },
    alertMsg: 'current user',
    toastFlag: false
  }).fetch<USER.CurrentUserReqBody>();
  if (res.success) {
    return res.payload
  }
  return null
}

export function postCurrenPassword(data: USER.CurrentPasswordResData) {
  return new GlobalAxios({
    axiosOptions: { url: ApiUrlLogin.CurrentPassword },
    alertMsg: 'current password'
  }).fetch<{ ok: boolean }>('POST', data);
}

export function postCurrentDestroy() {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlLogin.CurrentDestroy
    },
    alertMsg: "current destroy"
  }).fetch<{ ok: boolean }>('POST');
}

export function postCurrentEmail(data: USER.CurrentEmailIProps) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlLogin.CurrentEmail
    },
    alertMsg: "current email"
  }).fetch<{ ok: boolean }>('POST', data);
}
