/*
 * @Date: 2022-08-26 11:02:57
 * @LastEditors: Save
 * @LastEditTime: 2022-08-28 15:42:40
 * @FilePath: /src/services/thing.share.ts
 * @Description: 
 */
import { ApiUrlThingShare, ApiUrlThingGroup } from "@/api/api.url";
import { GlobalAxios } from "@/api/http";

export function onGetShareList(params: BASE.ListQuery) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingShare.thingsharers
    },
    alertMsg: "query thing share list"
  }).fetch<{list: any[]}>("GET", params)
}

export function onPostCreateShareUser(data: THING_SHARE.createShareUserReq) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingShare.thingsharers
    },
    alertMsg: "assign permission to someone"
  }).fetch("POST", data)
}

export function onDeleteThingSharersUser(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingShare.thingsharers}/${id}`
    },
    alertMsg: "Delete single thing sharer by id"
  }).fetch("DELETE")
}

export function onGetGroupsShareUserList(params: BASE.ListQuery) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingGroup.thinggroupauthorizeds
    },
    alertMsg: "Query thing group authorized list"
  }).fetch<{list: any[]}>("GET", params)
}

export function onDeleteGroupsShareUser(data: THING_SHARE.deleteGroupsUser) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingGroup.thinggroupauthorizeds}`
    },
    alertMsg: "Batch delete thing group authorized"
  }).fetch("DELETE", data)
}

export function onDeleteGroupsShareWithMe(groupId: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingGroup.delThingGroupShareWithMe.replace("{groupId}", groupId)
    },
    alertMsg: "Delete thing group shared with me"
  }).fetch("DELETE")
}

export function onGetThingShareDetails(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingShare.details.replace("{id}", id)
    },
    alertMsg: "Get single thing sharer by id"
  }).fetch("GET")
}
