import { TreeItemType } from '@/interface/enum';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { cloneDeep } from 'lodash';
import { mergeSocketItemToThingItem } from './thing.type.helper';
export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

export function getProjection(
  items: TREE.FlattenedItem[],
  activeId: string,
  overId: string,
  dragOffset: number,
  indentationWidth: number
) {
  const overItemIndex = items.findIndex(({id}) => id === overId);
  const activeItemIndex = items.findIndex(({id}) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem,
  });
  const minDepth = getMinDepth({nextItem});
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return {depth, maxDepth, minDepth, parentId: getParentId()};

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
  }
}

function getMaxDepth({previousItem}: {previousItem: TREE.FlattenedItem}) {
  if (previousItem) {
    return previousItem.depth + 1;
  }
  return 0;
}

function getMinDepth({nextItem}: {nextItem: TREE.FlattenedItem}) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

function flatten(
  items: TREE.TreeItems,
  parentId: string | null = null,
  depth = 0
): TREE.FlattenedItem[] {
  return items.reduce<any>((acc, item, index) => {
    return [
      ...acc,
      {...item, parentId, depth, index},
      ...flatten(item.children, item.id, depth + 1),
    ];
  }, []);
}

export function flattenTree(items: TREE.TreeItems): TREE.FlattenedItem[] {
  return flatten(items);
}

export function buildTree(flattenedItems: TREE.FlattenedItem[]): TREE.TreeItems {
  const root: TREE.TreeItem = {id: 'root', children: []};
  const nodes: Record<string, TREE.TreeItem> = {[root.id]: root};
  const items = flattenedItems.map((item) => ({...item, children: []}));
  for (const item of items) {
    const {id, children} = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);
    nodes[id] = {id, children};
    parent.children.push(item);
  }
  return root.children;
}

export function findItem(items: TREE.TreeItem[], itemId: string) {
  return items.find(({id}) => id === itemId);
}

export function findItemDeep(
  items: TREE.TreeItems,
  itemId: string
): TREE.TreeItem | undefined {
  for (const item of items) {
    const {id, children} = item;

    if (id === itemId) {
      return item;
    }
    if (children.length) {
      const child = findItemDeep(children, itemId);
      if (child) {
        return child;
      }
    }
  }

  return undefined;
}

export function removeItem(items: TREE.TreeItems, id: string) {
  const newItems = [];
  for (const item of items) {
    if (item.id === id) {
      continue;
    }
    if (item.children.length) {
      item.children = removeItem(item.children, id);
    }
    newItems.push(item);
  }

  return newItems;
}

export function setProperty<T extends keyof TREE.TreeItem>(
  items: TREE.TreeItems,
  id: string,
  property: T,
  setter: (value: TREE.TreeItem[T]) => TREE.TreeItem[T]
) {
  for (const item of items) {
    if (item.id === id) {
      item[property] = setter(item[property]);
      continue;
    }
    if (item.children.length) {
      item.children = setProperty(item.children, id, property, setter);
    }
  }

  return [...items];
}

export const setFindIndex = (items: TREE.TreeItems, typeStatus: string = TreeItemType.Groups) => {
  const listOnew: TREE.TreeItems = []
  const itemsMap: TREE.TreeItems = []
  items.forEach(item => {
    item.typeStatus = typeStatus
    if (item.prev_id === "0" || item.prev_id === "") {
      listOnew.push(item)
    } else {
      itemsMap.push(item)
    }
  })

  for (const listOnewItem of listOnew) {
    itemsMap.find((item, key) => {
      if (listOnewItem.id === item.prev_id) {
        listOnew.push(item)
        items.splice(key, 1)
        return item
      }
      return false
    })
  }
  return listOnew
}

export const setDeviceTypeStatus = (treeList: TREE.TreeItems, typeStatus: string = TreeItemType.Groups) => {
  for (const item of treeList) {
    item.typeStatus = typeStatus
    if (item.children && item.children.length) {
      item.children = setDeviceTypeStatus(item.children, typeStatus)
    }
  }
  return treeList
}

export function setHasLeafAllFun(treeList: TREE.TreeItems, headFlag: boolean = true) {
  for (const item of treeList) {
    if (headFlag) {
      if (!item.parent_id) {
        Reflect.set(item, "has_leaf_all", item.has_leaf)
        continue
      }
    } else {
      if (item.has_leaf) {
        Reflect.set(item, "has_leaf_all", item.has_leaf)
        continue
      }
    }
    if (item.children && item.children.length) {
      item.children = setHasLeafAllFun(item.children, false)
    }
  }
  return treeList
}

export function getTreeListSort(treeList: TREE.TreeItems) {
  for (const item of treeList) {
    if (item.children.length) {
      // console.log(`(${item.name})  Children 的排序 =>`, item.children)
      item.children = getTreeListSort(setFindIndex(item.children))
    }
  }
  return treeList
}

function countChildren(items: TREE.TreeItem[], count = 0): number {
  return items.reduce((acc, {children}) => {
    if (children.length) {
      return countChildren(children, acc + 1);
    }
    return acc + 1;
  }, count);
}

export function getChildCount(items: TREE.TreeItems, id: string) {
  if (!id) {
    return 0;
  }

  const item = findItemDeep(items, id);

  return item ? countChildren(item.children) : 0;
}

export function removeChildrenOf(items: TREE.FlattenedItem[], ids: string[]) {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
}

export function FetchUpdateTreelist(dragEndEvent: DragEndEvent, projected: any, items: TREE.TreeItems): TREE.getFetchUpdateTreeGroup | TREE.getFetchUpdateTreeSensor | TREE.getFetchUpdateTreeError | undefined {
  const { active, over } = dragEndEvent
  if (projected && over) {
    const { depth, parentId } = projected;
    const clonedItems: TREE.FlattenedItem[] = cloneDeep(flattenTree(items))
    const overIndex = clonedItems.findIndex(({id}) => id === over.id);
    if (clonedItems[overIndex].typeStatus === TreeItemType.IBox) {
      return {
        typeStatus: "error",
        status: "根路径不可修改"
      }
    }
    if (clonedItems[overIndex].typeStatus === TreeItemType.LoadMore) {
      return {
        typeStatus: "error",
        status: "不能和LoadMore换位置"
      }
    }
    const activeIndex = clonedItems.findIndex(({id}) => id === active.id);
    if (clonedItems[activeIndex].id !== clonedItems[overIndex].id || clonedItems[activeIndex].depth !== depth) {
      const activeTreeItem = clonedItems[activeIndex];
      clonedItems[activeIndex] = {...activeTreeItem, depth, parentId};
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);
      const newActiveIndex = newItems.findIndex(({id}) => id === active.id);
      
      if (newActiveIndex === -1) {
        const getActiveItem = getChildrenList(cloneDeep(newItems), active.id)
        const getOverItem = getChildrenList(cloneDeep(items), active.id)
        if (getActiveItem.patherItem?.typeStatus === TreeItemType.Groups && getActiveItem.fatherItem?.typeStatus === TreeItemType.Sensor) {
          return {
            typeStatus: "error",
            status: "Sensor中不能添加Group"
          }
        }

        if (getActiveItem.patherItem?.typeStatus === TreeItemType.Sensor && getActiveItem.fatherItem?.typeStatus === TreeItemType.Sensor) {
          return {
            typeStatus: "error",
            status: "Sensor中不能添加Sensor"
          }
        }

        if ([TreeItemType.Sensor, TreeItemType.Groups].includes(getActiveItem.patherItem?.typeStatus) && getActiveItem.fatherItem?.typeStatus === TreeItemType.LoadMore) {
          return {
            typeStatus: "error",
            status: "LoadMore中不能添加sensor或者Groups"
          }
        }

        if (getActiveItem.patherItem?.typeStatus === TreeItemType.Groups) {
          if (getActiveItem.fatherItem?.typeStatus === TreeItemType.IBox) {
            return {
              typeStatus: "error",
              status: "iBox不能添加Group"
            }
          }
          const { index, item } = getChildrenIndex(getActiveItem.list, active.id)
          return setObjectConfig(index, getActiveItem.list, item.parentId, TreeItemType.Groups, getActiveItem.patherItem)
        }

        if (getActiveItem.patherItem?.typeStatus === TreeItemType.Sensor) {
          if (getActiveItem.fatherItem?.id === getOverItem.fatherItem?.id) {
            return {
              items: [setAssemblyData(getActiveItem)],
              patherItem: getActiveItem.patherItem,
              typeStatus: "sensor"
            }
          } else {
            return {
              items: [
                setAssemblyData(getActiveItem),
                {
                  group_id: getOverItem.fatherItem?.id,
                  children: getOverItem.list.filter(item => {
                    if (item.typeStatus === TreeItemType.Sensor && getOverItem.patherItem?.id !== item.id) {
                      return item
                    }
                  }).map(item => item.id)
                }
              ],
              patherItem: getActiveItem.patherItem,
              typeStatus: "sensor"
            }
          }
        }

      } else {
        if (newItems[newActiveIndex].typeStatus !== TreeItemType.Sensor) {
          return setObjectConfig(newActiveIndex, newItems, "", TreeItemType.Groups)
        }
      }
    }
  }
  return {
    typeStatus: "error",
    status: "没有改变不需要更新"
  }
}

function setAssemblyData(data: TREE.getChildrenListInterface) {
  return {
    group_id: data.fatherItem?.id,
    children: data.list.filter(item => item.typeStatus === TreeItemType.Sensor).map(item => item.id)
  }
}

export function getChildrenList(items: TREE.TreeItems, id: string, fatherItem: TREE.TreeItem | null = null) {
  for (const item of items) {
    if (item.id === id) {
      // if (item.typeStatus === "sensor") {
      //   console.log(`匹配数据 --- sensor --- ${item.templateFormat.name} =>`, { item, items, fatherItem })
      // } else {
      //   console.log(`匹配数据 --- groups --- ${item.name} =>`, { item, items, fatherItem })
      // }
      return { list: items, patherItem: item, fatherItem: fatherItem }
    }
    if (item.children.length) {
      const childrenItem: { list: TREE.TreeItems, patherItem: TREE.TreeItem | null, fatherItem: TREE.TreeItem | null  } = getChildrenList(item.children, id, item)
      if (childrenItem.list.length) {
        return childrenItem
      }
    }
  }
  return { list: [], patherItem: null, fatherItem }
}

function getChildrenIndex(items: TREE.TreeItems, id: string) {
  const index = items.findIndex((item, key) => {
    if (item.id === id) {
      return { item, key }
    }
    return false
  })
  return {
    index,
    item: items[index]
  }
}

// export function setTreeToThingItem(items: TREE.TreeItems, socketItem: SCOKET.socketThingItem): TREE.TreeItems {
//   for (const item of items) {
//     if (item.typeStatus === "sensor") {
//       if (item.source.thing_id === socketItem?.thing_id) {
//         if (socketItem) {
//           switch (socketItem.publish_type) {
//             case PublishType.LATEST:
//               const { templateList } = getPayloadReturn(socketItem.payload)
//               item.templateFormat.payload = templateListFun(templateList, [...item.templateFormat.channels, ...item.templateFormat.payload_channels])
//             break
//             case PublishType.ALERT:
//               if (socketItem.payload.action === "online") {
//                 item.templateFormat.status = "Online"
//               } else {
//                 item.templateFormat.status = "Offline"
//               }
//             break
//           }
//           return items
//         }
//       }
//     }
//     if (item.children.length) {
//       const childrenItem = setTreeToThingItem(item.children, socketItem)
//       if (childrenItem.length) {
//         return childrenItem
//       }
//     }
//   }
//   return items
// }


export function setTreeToThingItem(items: TREE.TreeItems, socketList: SCOKET.onMessageProps[]): TREE.TreeItems {
  for (let item of items) {
    if (item.typeStatus === TreeItemType.Sensor) {
      item = mergeSocketItemToThingItem(item as any, socketList) as any
    }
    if (item.children.length) {
      item.children = setTreeToThingItem(item.children, socketList)
    }
  }
  return items
}

function setObjectConfig(activeIndex: number, items: TREE.TreeItems, parent_id: string = "", typeStatus: TREE.groupsType, patherItem: TREE.TreeItem | null = null) {
  const newItems: TREE.TreeItems = []
  items.forEach(item => {
    if (item.typeStatus === typeStatus) {
      newItems.push(item)
    }
  })
  const findIndex = newItems.findIndex(item => item.id === items[activeIndex].id)
  return mergeObjFun(findIndex, newItems, parent_id, typeStatus, patherItem)
}

const mergeObjFun = (index: number, items: TREE.TreeItems, parent_id: string = "",typeStatus: TREE.groupsType, patherItem: TREE.TreeItem | null = null) => {
  let reqData: TREE.getFetchUpdateTreeGroup = {
    id: items[index].id,
    data: {
      parent_id: parent_id,
      prev_id: "",
      next_id: ""
    },
    patherItem,
    typeStatus: "groups"
  }
  if (index === 0) {
    reqData = {
      ...reqData,
      data: {
        parent_id: parent_id,
        prev_id: "0",
        next_id: items.length === 1 ? "" : items[index + 1].id
      }
    }
  } else if (index === items.length - 1) {
    reqData = {
      ...reqData,
      data: {
        parent_id: parent_id,
        prev_id: items[index - 1].id,
        next_id: ""
      }
    }
  } else {
    reqData = {
      ...reqData,
      data: {
        parent_id: parent_id,
        prev_id: items[index - 1].id,
        next_id: items[index + 1].id
      }
    }
  }
  return reqData
}


export const setToggleLockGroups = (items: TREE.TreeItems, flag: boolean) => {
  for (const item of items) {
    item.disabled = flag
    if (item.children && item.children.length) {
      item.children =  setToggleLockGroups(item.children, flag)
    }
  }
  return items
}


export const getHasLeafToTrueAllFun = (items: TREE.TreeItems, ids: string[] = []): string[] => {
  // const ids: string[] = []
  for (const item of items) {
    if (item.has_leaf) {
      ids.push(item.id)
    }
    if (item.children && item.children.length) {
      getHasLeafToTrueAllFun(item.children, ids)
    }
  }
  return ids
}

export const getFilterTreeList = (items: TREE.TreeItems, setter: (item: TREE.TreeItem) => boolean, newItems: TREE.TreeItems = []) => {
  for (const item of items) {
    if (setter(item)) {
      newItems.push(item)
    }
    if (item.children && item.children.length) {
      getFilterTreeList(item.children, setter, newItems)
    }
  }
  return newItems
}

export const setIboxStatus = (items: TREE.TreeItems) => {
  return items.map((item, index) => {
    if (index === 0) {
      item.typeStatus = TreeItemType.IBox
    }
    return item
  })
}

