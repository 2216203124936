/*
 * @Date: 2022-04-24 22:36:38
 * @LastEditors: Save
 * @LastEditTime: 2022-07-03 21:02:19
 * @FilePath: /src/api/api.external.ts
 * @Description: 
 */

export const EXPLORER_HELIUM_URL = 'https://explorer.helium.com';
export const HELIUM_IO_URL = 'https://api.helium.io';
export const MAKER_URL = 'https://onboarding.dewi.org/api/v2';
export const HELIUM_CONSOLE = 'https://console.helium.com/api/v1'
export const BOBBER_URL = 'https://api.bobber.wtf/v1'

export const ExplorerHeliumApi = {
  hotspots: `${EXPLORER_HELIUM_URL}/hotspots`,
};

export const HeliumIoApi = {
  accounts: `${EXPLORER_HELIUM_URL}/accounts`,
  oraclePrices: `${HELIUM_IO_URL}/v1/oracle/prices/`,
  stats: `${HELIUM_IO_URL}/v1/stats`,
  maker: `${MAKER_URL}/makers`,
  makerHotspot: `${MAKER_URL}/hotspots`,
  earning: `${BOBBER_URL}/earning`,
};

export const HeliumConsoleApi = {
  devices: `${HELIUM_CONSOLE}/devices`
}