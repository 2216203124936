/*
 * @Date: 2022-06-09 01:48:59
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-18 18:09:43
 * @FilePath: /kittens/src/services/things.ts
 * @Description: 
 */
import { ApiUrlThingMetrics, ApiUrlThings, ApiUrlThingGroup, ApiUrlThingrules } from "@/api/api.url"
import { GlobalAxios } from "@/api/http"


export async function onGetThingList(params: THINGS.GetQueryThingList) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThings.things
    },
    alertMsg: "Query thing list"
  }).fetch<{ list: THINGS.GetThingListItem[] | null}>("GET", params)
}

export async function onPutThingsGroup(id: string, data: any) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThings.thingsGroup.replace("{id}", id)
    },
    alertMsg: "Update thing groupID by id"
  }).fetch<{list: any[]}>("PUT", data)
}

export async function onPostGatewaysToGroup(id: string, data: any) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingGroup.gatewayToGroups.replace("{id}", id)
    },
    alertMsg: "Add multiple hotspots to group"
  }).fetch<{list: any[]}>("POST", data)
}

export async function onPostThingsToGroup(id: string, data: any) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingGroup.thingToGroups.replace("{id}", id)
    },
    alertMsg: "Add multiple things to group"
  }).fetch<{list: any[]}>("POST", data)
}

export async function onPostThingsActive(data: Partial<THINGS.PostActiveReq>) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThings.thingsActive
    },
    alertMsg: "Active thing by AppEui and DevEui"
  }).fetch<THINGS.GetThingListItem>("POST", data)
}

export async function onPostThingsManualactive(data: Partial<THINGS.PostManualactiveReq>) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThings.thingsManualactive
    },
    alertMsg: "Manual activation device"
  }).fetch("POST", data)
}

export async function onDeleteThingsGroup(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThings.things}/${id}`
    },
    alertMsg: "Delete single thing by id"
  }).fetch("DELETE")
}

export async function onPutThingsAlias(id: string, alias: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThings.thingsGroupAlias.replace("{id}", id)
    },
    alertMsg: "Update thing alias by id"
  }).fetch("PUT", { alias })
}

export async function onPutThingsUserDefined(id: string, data: Partial<THINGS.PutThingUserDefinedReq>) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThings.things}/${id}`
    },
    alertMsg: "Update thing user-defined by id"
  }).fetch("PUT", data)
}


export async function onPatchThingStatusActivated(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThings.thingsActivated.replace("{id}", id)
    },
    alertMsg: "Update thing status to Activated"
  }).fetch("PATCH")
}

export async function onPatchThingStatusDisabled(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThings.thingsDisabled.replace("{id}", id)
    },
    alertMsg: "Update thing status to Disabled"
  }).fetch("PATCH")
}

export async function onPostThingCreate(data: THINGS.PostCreateThingsReq) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThings.things
    },
    alertMsg: "Batch create things"
  }).fetch("POST", data)
}

export async function onGetThing(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThings.things}/${id}`
    },
    alertMsg: "Get single thing by id"
  }).fetch<THINGS.GetThingListItem>("GET")
}

export async function onGetThingmetricsList(params: THINGS.ThingMetricsParams) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThingMetrics.query}`
    },
    alertMsg: "Query thing metrics data"
  }).fetch<{data: {value: number, timestamp: number}[]}>("GET", params)
}

export async function onDeleteThingsDevice(id: string) {
  return new GlobalAxios({
    axiosOptions: {
      url: `${ApiUrlThings.thingsDevice}/${id}`
    },
    alertMsg: "Delete single thing by id"
  }).fetch("DELETE")
}

export async function onPostThingRulesMute(data: THINGS.PostThingRulesMuteReq) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThingrules.thingrulesMute
    },
    alertMsg: "mute thing rule to stop doing actions"
  }).fetch("POST", data)
}

export async function onPostUpdateThingTree(data: any) {
  return new GlobalAxios({
    axiosOptions: {
      url: ApiUrlThings.thingsUpdategroup
    },
    alertMsg: "Update thing group and level by id"
  }).fetch("POST", data)
}
