/*
 * @Date: 2022-04-22 12:25:10
 * @LastEditors: Save
 * @LastEditTime: 2022-04-22 12:26:33
 * @FilePath: /src/type/storage.type.ts
 * @Description: 
 */
// storageType channelTypeMap
const StorageType = {
  TOKEN_DATA: 'TOKEN_DATA',
  CHANNEL_TYPE_FILE: "CHANNEL_TYPE_FILE",
  SUBSCRIPTION_CLAIM: "SUBSCRIPTION_CLAIM",
  THING_DETAILS_CHAT_INTERVAL: "THING_DETAILS_CHAT_INTERVAL",
  GATEWAY_MAKER: "GATEWAY_MAKER",
  SCENARIO: "SCENARIO"
}

export default StorageType
