export default [
  'attractive',
  'bald',
  'beautiful',
  'rare',
  'clean',
  'dazzling',
  'lucky',
  'elegant',
  'fancy',
  'fit',
  'fantastic',
  'glamorous',
  'gorgeous',
  'handsome',
  'long',
  'magnificent',
  'muscular',
  'plain',
  'able',
  'quaint',
  'scruffy',
  'innocent',
  'short',
  'skinny',
  'acrobatic',
  'tall',
  'proper',
  'alert',
  'lone',
  'agreeable',
  'ambitious',
  'brave',
  'calm',
  'delightful',
  'eager',
  'faithful',
  'gentle',
  'happy',
  'jolly',
  'kind',
  'lively',
  'nice',
  'obedient',
  'polite',
  'proud',
  'silly',
  'thankful',
  'winning',
  'witty',
  'wonderful',
  'zealous',
  'expert',
  'amateur',
  'clumsy',
  'amusing',
  'vast',
  'fierce',
  'real',
  'helpful',
  'itchy',
  'atomic',
  'basic',
  'mysterious',
  'blurry',
  'perfect',
  'best',
  'powerful',
  'interesting',
  'decent',
  'wild',
  'jovial',
  'genuine',
  'broad',
  'brisk',
  'brilliant',
  'curved',
  'deep',
  'flat',
  'high',
  'hollow',
  'low',
  'narrow',
  'refined',
  'round',
  'shallow',
  'skinny',
  'square',
  'steep',
  'straight',
  'wide',
  'big',
  'colossal',
  'clever',
  'gigantic',
  'great',
  'huge',
  'immense',
  'large',
  'little',
  'mammoth',
  'massive',
  'micro',
  'mini',
  'petite',
  'puny',
  'scrawny',
  'short',
  'small',
  'polished',
  'teeny',
  'tiny',
  'crazy',
  'dancing',
  'custom',
  'faint',
  'harsh',
  'formal',
  'howling',
  'loud',
  'melodic',
  'noisy',
  'upbeat',
  'quiet',
  'dandy',
  'raspy',
  'rhythmic',
  'daring',
  'zany',
  'digital',
  'dizzy',
  'exotic',
  'fun',
  'furry',
  'hidden',
  'ancient',
  'brief',
  'early',
  'fast',
  'future',
  'late',
  'long',
  'modern',
  'old',
  'prehistoric',
  'zesty',
  'rapid',
  'short',
  'slow',
  'swift',
  'young',
  'acidic',
  'bitter',
  'cool',
  'creamy',
  'keen',
  'tricky',
  'fresh',
  'special',
  'unique',
  'hot',
  'magic',
  'main',
  'nutty',
  'pet',
  'mythical',
  'ripe',
  'wobbly',
  'salty',
  'savory',
  'sour',
  'spicy',
  'bright',
  'stale',
  'sweet',
  'tangy',
  'tart',
  'rich',
  'rural',
  'urban',
  'breezy',
  'bumpy',
  'chilly',
  'cold',
  'cool',
  'cuddly',
  'damaged',
  'damp',
  'restless',
  'dry',
  'flaky',
  'fluffy',
  'virtual',
  'merry',
  'hot',
  'icy',
  'shiny',
  'melted',
  'joyous',
  'rough',
  'shaggy',
  'sharp',
  'radiant',
  'sticky',
  'strong',
  'soft',
  'uneven',
  'warm',
  'feisty',
  'cheery',
  'energetic',
  'abundant',
  'macho',
  'glorious',
  'mean',
  'quick',
  'precise',
  'stable',
  'spare',
  'sunny',
  'trendy',
  'shambolic',
  'striped',
  'boxy',
  'generous',
  'tame',
  'joyful',
  'festive',
  'bubbly',
  'soaring',
  'orbiting',
  'sparkly',
  'smooth',
  'docile',
  'original',
  'electric',
  'funny',
  'passive',
  'active',
  'cheesy',
  'tangy',
  'blunt',
  'dapper',
  'bent',
  'curly',
  'oblong',
  'sneaky',
  'overt',
  'careful',
  'jumpy',
  'bouncy',
  'recumbent',
  'cheerful',
  'droll',
  'odd',
  'suave',
  'sleepy',
]