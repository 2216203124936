export enum SourceType {
  owner = 'owner',
  host = 'host',
  all_latests = 'all_latests',
  assigned = 'assigned',
}

export enum ByType {
  forgot = 'forgot',
  register = 'register',
  create = 'create',
  update_email = 'update_email'
}

export enum WsType {
  TUNKNOWN = 0,
  DISCONNECT = 1,
  PING = 2,
  PONG = 3,
  PUBLISH = 4
}

export enum PublishType {
  PTUNKNOWN = 0,
  LATEST = 1,
  ALERT = 2
}

export enum thingDetailsRouteEnum {
  history = "history",
  chart = "chart",
  notifications = "notifications",
  setting = "setting",
  alert = "alert",
  map = "map",
  gateway = "gateway",
  detail = "detail",
  note = "note"
}

export enum WsAlertActionsType {
  Enter = "alert.enter",
  Leave = "alert.leave",
  Door = "door",
  Temperature = "temperature"
}

export enum TreeListItemMenu {
  AddGroup = "addGroup",
  AddDevice = "addDevice",
  SelectDevices = "selectDevices",
  SelectGateways = "selectGateways",
  EditGroup = "editGroup",
  DeleteGroup = "deleteGroup",
  ClickAlert = "clickAlert",
  ClickShareWith = "clickShareWith",
  DeleteGroupShareWithMe = "deleteGroupShareWithMe",
  DeleteGroupShareBy = "deleteGroupShareBy",
  PutLocation = "putLocation",
  GetGroupAndSensor = "getGroupAndSensor",
  GetDeviceList = "getDeviceList"
}

export enum TreeItemType {
  Groups = "groups",
  Sensor = "sensor",
  LoadMore = "loadMore",
  IBox = "Ibox"
}

export enum TreeSourceType {
  Owner = "owner",
  SharedWith = "sharedwith",
  Shared = "shared"
}

export enum DevicesType {
  Gateway = "gateway",
  Sensor = "sensor",
  Hotspot300 = "Bobcat Miner 300",
  Hotspot500 = "Bobber 500",
  HotspotOther = "Hotspot Other"
}

export enum SpeedTestTier {
  ACCEPTABLE = 'Acceptable',
  DEGRADED = 'Degraded',
  POOR = 'Poor',
  FAIL = 'Fail',
}

export enum Domain {
  STAGING = "STAGING",
  SUPPORT = "SUPPORT",
  CLOUD = "CLOUD",
  EBIKE = "EBIKE"
}

export enum GatewayCardLoadingStatus {
  pending = 1,
  fulfilled = 2,
  rejected = 3
}

export enum ProductNameEnum {
  BobberAppPremium = "bobber-app-premium",
  BobcatIoTBasicPlan = "bobcat-iot-basic-plan",
  BobcatIoTPremiumPlan = "bobcat-iot-premium-plan",
  RentAnIoTHotspot = "rent-an-iot-hotspot",
  RentAnIoTPackage = "rent-an-iot-package",
  bobberAppMonthly = "bobber-app-monthly",
  bobberAppYearly = "bobber-app-yearly",
  bobberIotYearly = "bobcat-iot-yearly"
}

export enum SharePermEnum {
  ROOT = "Root",
  RW = "rw",
  RO = "ro"
}

export enum ArticlesStatusEnum {
  draft = "Draft",
  published ="Published"
}

export enum ArticlesTypeEnum {
  Sensor = "Sensor",
  Hotspot ="Hotspot",
  Phone = "Phone",
  Others = "Others"
}

export enum ChanelsTypeEnum {
  batt = "batt",
  rssi = "rssi",
  mode = "mode",
  digital = "digital",
  analog = "analog",
  temp = "temp",
  rel_hum = "rel_hum",
  motion = "motion",
  door = "door",
  alarm = "alarm",
  times = "times",
  duration = "duration",
  leak = "leak",
  latitude = "latitude",
  longitude = "longitude",
  altitude = "altitude",
  accuracy = "accuracy",
  sats = "sats",
  hdop = "hdop",
  voltage = "voltage",
  offline = "offline",
  errors = "errors",
  text = "text",
  coord = "coord",
  hotspot = "hotspot"
}