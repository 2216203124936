/*
 * @Date: 2022-04-21 14:11:52
 * @LastEditors: Xiaowei Zhang
 * @LastEditTime: 2022-10-31 14:50:09
 * @FilePath: /kittens/src/App.tsx
 * @Description: 
 */
import './locales'
import './App.less'
import { ConfigProvider } from 'antd';
import { Routes, useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './store';
import storageType from './type/storage.type';
import InitStateSlice, { FETCH_CURRENT_USER } from './store/slice/init.state.slice';
import { useDidMount, useDidUpdate } from 'rooks';
import { ACCOUNT_ROUTERS, NO_ACCOUNT_ROUTERS, EXCEPTION_ROUTERS, LOADING_ROUTERS, permissionRouter, setRouters } from './pages/pages.router';
import { useMemo } from 'react';
import { Role } from './services/login';
import { useMixpanel } from './assets/utils/react-mixpanel-browser/mixpanel';
import { ProductNameEnum } from './interface/enum';

function App() {
  
  const { i18n } = useTranslation()
  const { pathname, search } = useLocation()
  const { currentUser, pathname: InitPathname } = useAppSelector("InitStateSlice")
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const mixpanel = useMixpanel();
  
  useDidMount(() => {
    //console.log('mixpanel', mixpanel)
    getAutoTokenFun()
  })

  // useDidUpdate(() => {
  //   localStorage.setItem("pathname", pathname)
  // }, [pathname])

  useDidUpdate(() => {
    navigate(InitPathname)
  }, [InitPathname])

  const getAutoTokenFun = async () => {
    const plansStringArr = [`/plans/${ProductNameEnum.bobberAppMonthly}/claim`, `/plans/${ProductNameEnum.bobberAppYearly}/claim`, `/plans/${ProductNameEnum.bobberIotYearly}/claim`]
    if (!plansStringArr.includes(pathname)) {
      const noAccountList = ['/user/login', '/user/register', '/user/forgot', "/"]
      const pathIndex = noAccountList.findIndex(ele => ele === pathname) === -1
      if (pathIndex) {
        const params = new URLSearchParams(search);
        const access_token = params.get('access_token');
        const token_type = params.get('token_type');
        const scenario = params.get('scenario');
        if (scenario) {
          localStorage.setItem(
            storageType.SCENARIO,
            JSON.stringify(scenario)
          )
        }

        const maker = params.get("maker")
        if (maker) {
          localStorage.setItem(
            storageType.GATEWAY_MAKER,
            JSON.stringify(maker.split(","))
          )
        }
        if (access_token && token_type) {
          localStorage.setItem(
            storageType.TOKEN_DATA,
            JSON.stringify({
              access_token: access_token,
              token_type: token_type,
            }),
          );
        }
        dispatch(FETCH_CURRENT_USER()).then(res => {
          if (res.type === FETCH_CURRENT_USER.fulfilled.type) {
            dispatch(InitStateSlice.actions.setPathname(pathname))
          }
        })
      } else {
        localStorage.removeItem(storageType.TOKEN_DATA)
        if (pathname === "/") {
          navigate("/user/login")
        } else {
          navigate(pathname)
        }
      }
    }
  }
  
  const AccountRouter = useMemo(() => {
    if (currentUser !== null) {
      const getPermissionRouter = permissionRouter(ACCOUNT_ROUTERS, currentUser.role)
      return setRouters(getPermissionRouter)
    }
    return null
  }, [currentUser])

  const NoAccountRouter = useMemo(() => {
    const getPermissionRouter = permissionRouter(NO_ACCOUNT_ROUTERS, Role.Root)
    const loginRouter = setRouters(getPermissionRouter)
    return loginRouter
  }, [])

  const ExceptionRouters = useMemo(() => {
    const noAccountList = ['/user/login', '/user/register', '/user/forgot', "/"]
    if (noAccountList.includes(pathname) || currentUser !== null) {
      return setRouters(EXCEPTION_ROUTERS)
    }
    return setRouters(LOADING_ROUTERS)
  }, [currentUser])

  return (
    <ConfigProvider componentSize="middle" locale={{locale: i18n.language}}>
      <Routes>
        {NoAccountRouter}
        {AccountRouter}
        {ExceptionRouters}
      </Routes>
    </ConfigProvider>
  );
}

export default App;
