/*
 * @Date: 2022-05-11 03:28:24
 * @LastEditors: Save
 * @LastEditTime: 2022-08-04 02:55:30
 * @FilePath: /src/store/slice/qr.slice.ts
 * @Description: 
 */
import cloneDeep from "lodash/cloneDeep";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface QrSliceProps {
  qrCode: string
  camerasArray: {id: string, label: string}[]
}

const initialState: QrSliceProps = {
  qrCode: "",
  camerasArray: []
}

const QrSlice = createSlice({
  name: "qrSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    setQrCode: (state, action: PayloadAction<string>) => {
      state.qrCode = action.payload
    },
    setCamerasArray: (state, action: PayloadAction<{id: string, label: string}[]>) => {
      state.camerasArray = action.payload
    },
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  }
})

export default QrSlice