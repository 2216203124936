/*
 * @Date: 2021-12-30 16:25:40
 * @LastEditors: Save
 * @LastEditTime: 2022-09-17 01:17:27
 * @FilePath: /src/assets/utils/base.ts
 * @Description: 
 */
import { envConfig } from '@/api/api.url';
import type { RequestData } from '@ant-design/pro-table';

const mobileMakers = new Set(['19', '67']);
const heros = new Set([
  '11n6P6uF438y6h9oeAza7rnSzmMKTt1mq6vt4s2vKxhBrjVkgTP',
  '112gXULCJSyr5W4n7z7PCgEtJ6G5ajWKyifxK2UjQ6DK2HMDqfAJ',
  '11Yue72FC8QznL2kTJRHmHVfJGdEwqSgqR6jS3Q74Hj1j4jB645',
  '11eQ1QZ1LWKT4eURPtmVr85gTkSETaQnGMKYq9KXk8iRbRpSiJZ',
  '11hkoTooFERCMwt7g4ZbZqvpYXx6s78iXJ93jRCA1M33pmThBxi',
  '11h56HsxxbttN6AGdefrgc6yc8diEZwpzXzT2p7pfc52LpJteAG',
  '112gfcxAcgsZL5ACmYTjvoBHVhGgtm4dPAVyHQwTcMobbTtfSepr',
  '112D4J5yKWdqExXhr14EoDi2C2KKV58ddM2pv1EZKy5gAGjfE3nQ',
  '112suCafmyT5CNrQx9ZNTRVxi77h15WoPf5qP4mGB6BCimYwsFxQ',
  '11251PMB4F3xFcWygVTsRdvzbVuqjiHzpMJZ4iS4DB4Mm4HQca8k',
  '112Wf4HpEYtmtLUUuJVqHoqg6eukRwtDCDHHPdoFHJ4F8sD5GcQR',
  '1129V49mLw56ASfHmaNCsRLRDfyaULowHe3b4E8Ti4tkfJDwvNne',
  '112LT4hFjf5vq9MMGEKzR9W4vhdJ48cRFaYJF45TLPB9sAdcBzYa',
  '11uBCU8GnWQkvHSnE6xK5EFhThTJoa4tG8VpQ8iE4LWATYzXUK1',
  '11CHSkpon9nCFs2H1EarV5M8QVx6c2RykYT9qBPNfjg6WSfDRnW',
  '117PA8D71NVqNFHoipw33Vue3ryA7tuGFq85heXbRPDHAnJKyN3',
  '112dxLFt8RZVCxFjoAFUrHb7ePnV21uFCY9AaksZoKy9M3X39eZD',
  '11ZxwrXnBEHDwHWD4k4vtcz7DvwqTKCafWqH3d5ZNLyYCcX5tpz',
  '112V8KuitHuBxV39Eckj631WdBo7cUL8N5TgepHbV8nyUJPXDptb',
  '11dMhjD4YcP2spuAdpFfh38HMW8wjPQstmyb5WQsG8ciZjyKaZe',
  '112DZyZdS8bBWpqfFzdRaxg8h9ouHgeKnbWFaDbXqRu4nb276Zv5',
  '112kUmrtgqNebqzG8PCDqS6vrJF8AdcFjFPbKk4ZH9ddg6gMngwA',
  '112mGiYPHT5eUtXpMqTx7uUUAJHhTP7UXKbDcNqogD8Mt4QCh55o',
  '11daU78LogEH8i5YJfZFeUURYMhkfJP435TvU3MvP7KzcTszX5g',
  '112e2kpjLkiL7zgw7MVQEymUHtjFuzUFsub4zvpuUrW5fpqTuAg9',
  '112FctFcDsSHu3BAb4PbNc4MgTDsmnb4facMzYLQead2YknRH6nv',
  '11StzD736bjg6bTzqdwjc65hMeyyrmkm4dxkoTVAAWfQQb8EPq5',
  '11LvXTkXrmRexkGAH9vBUXzzfgy7uDbB2cz5ahCWh6L9v2RpYMD',
  '1126ahjisbhb2TVq2S9NemHt7WuWFegmF2ybJrxmBui8Z9FHwcjn',
  '112t6wEYfbFMBk8KZ5c8RypfX9Uv8iTB3pHU8FF6eeQFLnhFqmbr',
  '112bnFQBxx3xrJvneBDLRfTJcPap5vbv3eFYjV7Sj3mzMfmRDsRk',
  '112GKiuzCYDGtA4ez6yfzdMv6uwmBZpbdohhQ3W2nM5PmLWoRw6b',
  '112DJNVsdZBeHPZDgMXXjwRm463b9Y8sjq1JWVNiFoxw8ZM2LSwZ',
  '111Dqy1xx1YfDhssD577QbFyk6UsaMmmMWnLfJXu8XYfPANAzRP',
  '112XVswNwT7nH3HhZCAw7rHaYnKUpXyEosur3rDxd8sJwSHTzRRN',
  '11oVXKPhQ8n2M47JurPhrMMLzXMQTxiAVVVumYXgBkjwzYik9ch',
  '11a9KuiD3EvoYKGpNLq79CZLLr2NSEvHG6VWdAZZdcsvJ1c18pQ',
  '112eCtN18BZd79N7uD8oVYqg2W1W6wxbnzhbaryDnNmZtNhDUmg2',
  '112dPBRaFgS75otmzEVfppXf6GQwBSD1NywxRUJwvcefEK1Dowpo',
  '11AWKibfCyovLQCimYwbxrNYLUBZRz2mU9dgHKP3T6bHJxF6Boh',
  '112PUtynzQYsFZPLQ4vX74viXAQtSRLx43UZPWM5ug8EjRVasa2Q',
]);

export async function requestDataFunction<T>(
  req: API.RequestAPI<any, any>,
  setter?: (list: T[]) => void
): Promise<RequestData<T>> {
  if (req.success) {
    return {
      data: typeof setter === "function" ? setter(req.payload.list || []) :  req.payload.list || [],
      success: true,
      total: req.payload.pagination.total,
    };
  }

  return {
    data: [],
    success: false,
    total: 0,
  };
}

export function isUppercase(i: string) {
  if (typeof i !== 'string') return i;
  let lable: string = '';
  if (i.indexOf('_') === -1) {
    lable = i.replace(i[0], i[0].toLocaleUpperCase());
  } else {
    lable = i
      .replaceAll('_count', '')
      .split('_')
      .map((item) => item.replace(item[0], item[0].toLocaleUpperCase()))
      .join(' ');
  }
  return lable;
}

export function setArrayByCsv<T extends object>(csvString: string) {
  try {
    csvString = csvString.trim()
    const csletry = csvString.split("\r\n");
    const datas: T[] = []; 
    const headers = csletry[0].split(",");
    csletry.forEach(headersItem => {
      const temp = headersItem.split(","); 
      let data: any = {}
      temp.forEach((tempItem, tempKey) => {
        data[headers[tempKey]] = tempItem
      })
      datas.push(data); 
    })
    datas.splice(0, 1)
    return datas
  } catch (error) {
    return false
  }
}

export function isBobcatGateway(gateway: GATEWAY.GatewayListItem | THINGS.thingItem) {
  return gateway?.maker_id === '6' && !heros.has(gateway?.pubkey);
}

export function isBobcat5G(gateway: GATEWAY.GatewayListItem) {
  return gateway?.maker_id === '67' || heros.has(gateway?.pubkey);
}

export function isMobileGateway(gateway: GATEWAY.GatewayListItem | Record<string, any>) {
  return mobileMakers.has(gateway?.maker_id) || heros.has(gateway?.pubkey);
}

export function isPilotGateway(gateway: GATEWAY.GatewayListItem| Record<string, any>) {
  return heros.has(gateway.pubkey);
}

export function generateFullPath(path?: string) {
  if (path) {
    return `/${envConfig.REACT_APP_ROOT_PATH}/${path}`
  }
  return `/${envConfig.REACT_APP_ROOT_PATH}`
}

/**
 * 首字母大写
 * @param str string
 * @returns str
 */
export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

