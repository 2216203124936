/*
 * @Date: 2022-04-27 13:54:35
 * @LastEditors: Save
 * @LastEditTime: 2022-08-04 02:54:09
 * @FilePath: /src/store/slice/json.rule.form.ts
 * @Description: 
 */

import cloneDeep from "lodash/cloneDeep";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initState {
  formData: RULE.CreateRuleReqData | null;
}

const initialState: initState = {
  formData: null
}

const JsonRuleFormSlice = createSlice({
  name: "JsonRuleFormSlice",
  initialState: cloneDeep(initialState),
  reducers: {
    setTarget: (state, action: PayloadAction<{key: number, value: string}>) => {
      if (state.formData !== null) {
        state.formData.actions[action.payload.key].target.type = action.payload.value;
      }
    },
    deleteItem: (state, action: PayloadAction<{typeName: "triggers", index: number}>) => {
      const { typeName, index } = action.payload
      if (state.formData !== null) {
        state.formData[typeName].splice(index, 1);
      }
    },
    setJson: (state, action: PayloadAction<{jsonObj: { key: number, json: any }}>) => {
      const { json, key } = action.payload.jsonObj
      if (state.formData !== null) {
        state.formData.actions[key].extra = JSON.stringify(json);
      }
    },
    setRuleBody: (state, action: PayloadAction<any>) => {
      state.formData = action.payload;
    },
    createTriggers: (state) => {
      if (state.formData !== null && !state.formData.triggers.length) {
        state.formData.triggers = []
      }
      state.formData?.triggers.push({
        thing_id: '',
        type: 'condition',
        extra: '',
        expressions:
          '{{ and (gt (len .message.Payload.Errors) 0) (eq (len .last_payload.Errors) 0) }}',
      });
    },
    createActions: (state) => {
      if (state.formData !== null && !state.formData.actions.length) {
        state.formData.actions = []
      }
      state.formData?.actions.push({
        type: 'notification',
        extra: '{"type":"","status":"","icon":"","style":"","expired":0}',
        subject: '',
        body: '',
        target: {
          type: '',
          users: '',
        },
        users: '',
        target_type: '',
      });
    },
    clearInitState: () => {
      return cloneDeep(initialState)
    }
  }
})

export default JsonRuleFormSlice